/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ConfirmTransactionDetailsPageWrapper } from '../../Settlements/components/ConfirmTransactionsDetailsPageWrapper';

const Referral = ({ profile }) => {
  const userReferralCode = profile?.investor?.userReferralCode;
  return (
    <>
      <ConfirmTransactionDetailsPageWrapper
        title={'Referral Code'}
        classes={'mt-10'}
      >
        <div className="text-center tex-16">
          <div className="mt-10 mx-5 px-md-20 px-5">
            Invite your friends. Earn a referral bonus. For every friend you
            refer who invests on ndovu, you will get a referral bonus of $5!
            limited to 5 referrals. Hurry this is a limited offer!
          </div>

          <div className="mt-10 mx-5">
            <span>Referral ID:</span>{' '}
            <span className="text-blue font-weight-500">
              {userReferralCode ? userReferralCode : '00000'}
            </span>
          </div>
          <div className="mx-5 my-10">
            <p> Get your friends in on the fun. Happy Referring!</p>
          </div>
        </div>
      </ConfirmTransactionDetailsPageWrapper>
    </>
  );
};

export default Referral;
