import React from 'react';

import LowRisk from './PlanCard/LowRisk';
import MediumRisk from './PlanCard/MediumRisk';
import HighRisk from './PlanCard/HighRisk';
import ExtremelyLowRisk from './PlanCard/ExtremelyLow';

export const RiskLevel = ({
  planRiskLevel,
  onClick,
  squareSize,
  riskLevelFontSize,
  extremelyLowSquareWidth,
}) => {
  const numberOfSquares = [0, 1, 2, 3, 4, 5, 6];

  return (
    <div
      className="d-flex flex-column align-content-end mt-2"
      onClick={onClick}
    >
      {planRiskLevel?.id === 1 ? (
        <LowRisk squareSize={squareSize} squares={numberOfSquares} />
      ) : planRiskLevel?.id === 2 ? (
        <MediumRisk squareSize={squareSize} squares={numberOfSquares} />
      ) : planRiskLevel?.id === 3 ? (
        <HighRisk squares={numberOfSquares} squareSize={squareSize} />
      ) : (
        planRiskLevel?.id === 4 && (
          <ExtremelyLowRisk
            squares={numberOfSquares}
            squareSize={squareSize}
            extremelyLowSquareWidth={extremelyLowSquareWidth}
          />
        )
      )}
      <div
        className={`text-dark-gray-550 font-weight-bold ${
          riskLevelFontSize ? riskLevelFontSize : 'text-14'
        } text-right`}
      >
        Risk level: <span className="text-blue">{planRiskLevel?.name}</span>
      </div>
    </div>
  );
};
