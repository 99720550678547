import * as Yup from 'yup';
import * as profile from '../../Profile/_redux/profileRedux';

import React, { useState } from 'react';

import Alert from '../../Common/Alert';
import { connect } from 'react-redux';
import { getUserProfile } from '../../Profile/_redux/profileCrud';
import { sendReferralCode } from '../_redux/referralActions';
import { useFormik } from 'formik';
import CustomInput from '../../Common/CustomInput';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { ConfirmTransactionDetailsPageWrapper } from '../../Settlements/components/ConfirmTransactionsDetailsPageWrapper';

const DiscountInactive = ({ profileAction }) => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const initialValues = {
    voucher: '',
  };

  const voucherCodeSchema = Yup.object().shape({
    voucher: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(8, 'Maximum 8 characters')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: voucherCodeSchema,
    onSubmit: async values => {
      try {
        const response = await sendReferralCode(null, values.voucher);

        if (response.status === 200) {
          formik.resetForm();
          const userProfile = await getUserProfile();
          profileAction(userProfile.data.data);
        }
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
        setTimeout(() => {
          setAlertOn(false);
        }, 3000);
      }
    },
  });
  return (
    <>
      <ConfirmTransactionDetailsPageWrapper
        title={'Corporate Code'}
        classes={'mt-10'}
      >
        <div className="mt-10 text-center mx-5">
          <p className="px-md-20 px-5">
            We work with employers to provide their employees with a discount
            when it comes to building wealth. If you have a corporate code,
            please insert it here to unlock your discount. If you would like us
            to partner with your employer, email us at support@ndovu.co
          </p>
        </div>

        <div className="form-group fv-plugins-icon-container m-10">
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework px-md-20 px-lg-40"
          >
            <CustomInput
              type="text"
              name="voucher"
              formik={formik}
              required={true}
              placeholder={'Voucher'}
              classes="mx-auto"
            />

            {alertOn ? <Alert alert={alert} /> : null}

            <div className="text-center my-5">
              <ConfirmButton
                buttonType={'submit'}
                buttonText={'Submit'}
                buttonWidth={'width-345'}
                disable={
                  !formik?.values?.voucher ||
                  formik?.values?.voucher?.length < 8
                }
              />
            </div>
          </form>
        </div>
      </ConfirmTransactionDetailsPageWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { ...profile.actions })(
  DiscountInactive,
);
