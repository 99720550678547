import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import HorizontalLine from '../../helpers/horizontalLine';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '30%',
    height: '90vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const SelectOptionsModal = ({
  open,
  handleSelect,
  handleClose,
  allCountries,
  selectedCountry,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [filteredCountries, setFilteredCountries] = useState();
  const [dropdownTopCountries, setDropdownTopCountries] = useState();

  const ndovuCountries = ['RW', 'KE', 'UG'];

  useEffect(() => {
    const ndovuCountriesList = [];
    selectedCountry &&
      allCountries &&
      allCountries.forEach((item, i) => {
        if (
          item.name === selectedCountry?.name &&
          !ndovuCountries.includes(selectedCountry?.countryCode)
        ) {
          allCountries.splice(i, 1);
          allCountries.unshift(item);
        }

        if (ndovuCountries.includes(item?.countryCode)) {
          ndovuCountriesList.push(item);
        }
      });

    setDropdownTopCountries(ndovuCountriesList);
    setFilteredCountries(allCountries);
  }, [allCountries, selectedCountry]);

  const handleChange = event => {
    const { value } = event.target;
    const searchResults = allCountries.filter(country =>
      country.name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredCountries(searchResults);
  };

  const countryListItem = country => {
    return (
      <div
        className="d-flex justify-content-between mt-1 cursor-pointer"
        onClick={() => handleSelect(country)}
        key={country.name}
      >
        <div>
          {country?.flag ? (
            <span className={`flag-icon ${country?.flag}`}></span>
          ) : null}
          <span
            style={{
              color:
                selectedCountry?.countryCode === country.countryCode
                  ? '#0071CE'
                  : '',
            }}
            className="ml-2"
          >
            {country?.name}
          </span>
        </div>
        {selectedCountry?.countryCode === country.countryCode ? (
          <i
            className="fa fa-check"
            style={{ color: '#0071CE', fontSize: '12px' }}
            aria-hidden="true"
          ></i>
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <input
          onChange={handleChange}
          type="text"
          name="searchCountry"
          className="border-gray-450 px-3 height-45 border-radius-8 w-100 mb-5"
          placeholder="Search"
          autoComplete="off"
        />

        {dropdownTopCountries?.map(country => countryListItem(country))}

        <HorizontalLine width={'100%'} height={'1'} color="#cccccc" />

        {filteredCountries?.map(country => countryListItem(country))}
      </div>
    </Modal>
  );
};

export default SelectOptionsModal;
