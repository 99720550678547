import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import { requestKYCupdate } from '../../BusinessChama/_redux/kycCrud';
import KycChangeReasonModal from '../../KYC/Components/KycChangeReasonModal';
import Button from '../../Common/Button';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import { PermissionModalContent } from '../../Common/PermissionModalContent';
import {
  accountTypes,
  idTypeOptions,
  kycFlowActions,
  kycStatuses,
  teamMemberRole,
} from '../../../helpers/constants';
import { KycCompletedModalContent } from '../../Common/KycCompletedModalContent';
import Back from '../../Common/BackArrow';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import { getCountryCode } from '../../../helpers/getCountryCode';
import { Country } from 'country-state-city';
import { DocumentsWrapper } from '../../Individual/components/DocumentsWrapper';
import { DisplayDocuments } from '../../Individual/components/DisplayDocuments';
import Alert from '../../Common/Alert';
import { SummaryPageRow } from '../../Individual/components/SummaryPageRow';
import { buschamaAdditionalDetails } from '../_redux/busChamaCrud';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { InvestorInformationSummaryTitle } from '../components/InvestorInformationSummaryTitle';
import { TopBottomSpacing } from '../../Individual/components/TopBottomSpacing';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';

const BusinessOverview = props => {
  const {
    id: stepperId,
    handleStepChange,
    investorInformationSummaryPage,
  } = props;

  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [displayConfirmPage, setDisplayConfirmPage] = useState(false);
  const [reason, setReason] = useState('');
  const [countryCode, setCountryCode] = useState();
  const [directorCountryCode, setDirectorCountryCode] = useState();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const profile = useSelector(state => state.profile);
  const { investor, user } = profile;
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const kycInformation = useSelector(state => state.kyc);

  const dispatch = useDispatch();

  const countryCodeOrName = investor?.country?.toLowerCase();
  const directorCountryCodeOrName = investor?.businessChamaDirectorCountry?.toLowerCase();

  const updateCountryDetails = async (countryCodeOrName, updateCountryCode) => {
    const { countryCode: investorCountryCode } = await getCountryCode(
      countryCodeOrName,
    );

    updateCountryCode(investorCountryCode);
  };

  useEffect(() => {
    updateCountryDetails(countryCodeOrName, setCountryCode);
    updateCountryDetails(directorCountryCodeOrName, setDirectorCountryCode);
    handleStepChange({ next: stepperId });
  }, []);

  const replaceString = value => {
    if (value) {
      return value.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    }
    return '';
  };

  const sendKYCupdateRequest = async () => {
    try {
      setAlert({
        alertMessage: null,
        alertMessageType: null,
      });
      const res = await requestKYCupdate(reason);

      if (res.status === 201) {
        setDisplayConfirmPage(true);
        setOpenReasonModal(false);
      }
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
    }
  };

  const renderConfirmation = () => {
    return (
      <div className="card card-custom gutter-b p-md-10 p-lg-10 mt-5">
        <div
          className="card-body business-overview"
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: '7.111px',
            border: '0.267px solid #D9EAF8',
          }}
        >
          <div className="d-flex justify-content-center align-items-center p-5">
            <span
              className="text-center"
              style={{ color: '#0071ce', fontWeight: 500 }}
            >
              Your request has been received and our team will contact you with
              the instructions to change the details.
              <br></br>Please, allow 3 business days.
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-10">
            <Button
              btnText="Exit to dashboard"
              className="text-center common-btn btn-size"
              onClick={() => history.push('/home')}
            />
          </div>
        </div>
      </div>
    );
  };

  const checkUpdatePermission = () => {
    if (user?.businessChamaRole?.name !== teamMemberRole.ADMIN) {
      setOpenPermissionModal(true);
    } else {
      setOpenReasonModal(true);
    }
  };

  const handleHide = () => {
    setOpenSuccessModal(false);
    dispatch(kycActions.cleanKycState());
    history.push('/plans');
  };

  const submittingKyc =
    kycInformation?.kycAction === kycFlowActions.SUBMITTING_KYC_INFORMATION;

  const isChamaAccount = investor?.investorType === accountTypes.CHAMA;
  const isBusinessAccount = investor?.investorType === accountTypes.BUSINESS;

  const handleButtonClick = async () => {
    setLoading(true);

    if (investorInformationSummaryPage) {
      dispatch(
        kycActions?.updateKycInfo({
          kycAction: kycFlowActions.UPDATING_KYC_INFORMATION,
        }),
      );
      const { pathname } = history.location;
      localStorage.setItem('previousPage', pathname);

      history.push('/investor-information/create-account');
    } else {
      const {
        sourceOfFunds,
        city,
        businessChamaBusinessAddress,
        postalAddress,
        postalCode,
        businessChamaEmail,
        phoneNumber,
        nextOfKinNames,
        nextOfKinEmail,
        nextOfKinContact,
      } = kycInformation;

      try {
        await buschamaAdditionalDetails({
          sourceOfFunds,
          city,
          businessChamaBusinessAddress,
          postalAddress,
          postalCode,
          businessChamaEmail,
          phoneNumber,
          nextOfKinNames,
          nextOfKinEmail,
          nextOfKinContact,
        });

        setLoading(false);

        setOpenSuccessModal(true);
      } catch (error) {
        setLoading(false);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
      }
    }
  };

  const previousPage = localStorage.getItem('previousPage');
  const handlePreviousButtonClick = () => {
    if (previousPage) {
      history.push(previousPage);
      localStorage.removeItem('previousPage');
    } else {
      history.push('/manage-account');
    }
  };

  const renderDetails = () => {
    return (
      <KycPageContentWrapper>
        <div>{alert?.alertMessage ? <Alert alert={alert} /> : null}</div>

        <div className="d-flex justify-content-between align-items-start flex-wrap">
          <InvestorInformationSummaryTitle
            firstName={user?.firstName}
            lastName={user?.lastName}
            investorType={investor?.investorType}
            businessChamaName={investor?.businessChamaName}
          />

          {investorInformationSummaryPage &&
          investor?.kycStatus === kycStatuses.COMPLETED ? (
            <ConfirmButton
              buttonText={'UPDATE INVESTOR INFO'}
              handleOnClick={() => checkUpdatePermission()}
              loading={loading}
              disable={investor?.hasRequestedToEditKyc}
            />
          ) : null}
        </div>

        <div className="blue-line mt-5"></div>

        <div className="mt-8">
          {investor?.businessChamaName ? (
            <SummaryPageRow
              label={isChamaAccount ? 'Chama Name' : 'Business Name'}
              value={investor?.businessChamaName}
            />
          ) : null}

          {investor?.businessChamaEmail ||
          kycInformation?.businessChamaEmail ? (
            <SummaryPageRow
              label={isChamaAccount ? 'Chama Email' : 'Business Email'}
              value={
                kycInformation?.businessChamaEmail
                  ? kycInformation?.businessChamaEmail
                  : investor?.businessChamaEmail
              }
            />
          ) : null}

          {investor?.phoneNumber || kycInformation?.phoneNumber ? (
            <SummaryPageRow
              label={'Phone Number'}
              value={
                kycInformation?.phoneNumber
                  ? kycInformation?.phoneNumber
                  : investor?.phoneNumber
              }
            />
          ) : null}

          <TopBottomSpacing />

          {investor?.businessChamaBusinessAddress ||
          kycInformation?.businessChamaBusinessAddress ? (
            <SummaryPageRow
              label={isChamaAccount ? 'Chama Address' : 'Business Address'}
              value={
                kycInformation?.businessChamaBusinessAddress
                  ? kycInformation?.businessChamaBusinessAddress
                  : investor?.businessChamaBusinessAddress
              }
            />
          ) : null}

          {investor?.postalAddress || kycInformation?.postalAddress ? (
            <SummaryPageRow
              label={'Postal Address'}
              value={
                kycInformation?.postalAddress
                  ? kycInformation?.postalAddress
                  : investor?.postalAddress
              }
            />
          ) : null}

          {investor?.postalCode || kycInformation?.postalCode ? (
            <SummaryPageRow
              label={'Postal Code'}
              value={
                kycInformation?.postalCode
                  ? kycInformation?.postalCode
                  : investor?.postalCode
              }
            />
          ) : null}

          {investor?.city || kycInformation?.city ? (
            <SummaryPageRow
              label={'City'}
              value={
                kycInformation?.city ? kycInformation?.city : investor?.city
              }
            />
          ) : null}

          {countryCode ? (
            <SummaryPageRow
              label={'Country'}
              value={Country.getCountryByCode(countryCode)?.name}
            />
          ) : null}

          <TopBottomSpacing />

          {investor?.businessChamaRegistrationNumber ? (
            <SummaryPageRow
              label={`${isChamaAccount ? 'Chama ' : 'Business '} 
                  Registration Number`}
              value={
                replaceString(investor?.businessChamaRegistrationNumber) || ''
              }
            />
          ) : null}

          {isBusinessAccount && investor?.businessType ? (
            <SummaryPageRow
              label={'Business Type'}
              value={capitalizeTheFirstLetterOfEachWord(
                replaceString(investor?.businessType),
              )}
            />
          ) : null}

          {investor?.sourceOfFunds || kycInformation?.sourceOfFunds ? (
            <SummaryPageRow
              label={'Source of Funds'}
              value={capitalizeTheFirstLetterOfEachWord(
                replaceString(
                  kycInformation?.sourceOfFunds
                    ? kycInformation?.sourceOfFunds
                    : investor?.sourceOfFunds,
                ),
              )}
            />
          ) : null}

          {investor?.taxPayerNumber ? (
            <SummaryPageRow
              label={`${isChamaAccount ? 'Chama' : 'Business'} Tax No`}
              value={investor?.taxPayerNumber}
            />
          ) : null}
        </div>

        <TopBottomSpacing />

        <div className="mt-3">
          {investor?.businessChamaDirectorName ? (
            <SummaryPageRow
              label={
                isChamaAccount
                  ? `Treasurer's Full Name`
                  : `Director's Full Name`
              }
              value={investor?.businessChamaDirectorName}
            />
          ) : null}

          {investor?.businessChamaDirectorIdentity
            ?.businessChamaAdminIdentityType ? (
            <SummaryPageRow
              label={
                isChamaAccount ? `Treasurer's ID Type` : `Director's ID Type`
              }
              value={
                idTypeOptions[
                  investor?.businessChamaDirectorIdentity
                    ?.businessChamaAdminIdentityType
                ]
              }
            />
          ) : null}

          {investor?.businessChamaDirectorIdNumber ? (
            <SummaryPageRow
              label={
                isChamaAccount
                  ? `Treasurer’s ${
                      idTypeOptions[
                        investor?.businessChamaDirectorIdentity
                          ?.businessChamaAdminIdentityType
                      ]
                    } Number`
                  : `Director's ${
                      idTypeOptions[
                        investor?.businessChamaDirectorIdentity
                          ?.businessChamaAdminIdentityType
                      ]
                    } Number`
              }
              value={investor?.businessChamaDirectorIdNumber}
            />
          ) : null}

          {directorCountryCode ? (
            <SummaryPageRow
              label={
                isChamaAccount
                  ? `Treasurer’s ID Country`
                  : `Director's ID Country`
              }
              value={Country.getCountryByCode(directorCountryCode)?.name}
            />
          ) : null}
        </div>

        <TopBottomSpacing />

        <div className="text-14 font-weight-500 mt-15">
          <div className="text-blue">Uploaded Documents</div>
          <div>{`The following are documents submitted by investor`}</div>
        </div>

        <TopBottomSpacing />

        <div className="mt-5">
          <DocumentsWrapper>
            <DisplayDocuments
              file={
                investor?.businessChamaDirectorIdentity?.passportOrNationalId
              }
              label={
                idTypeOptions[
                  investor?.businessChamaDirectorIdentity
                    ?.businessChamaAdminIdentityType
                ]
              }
              setAlert={setAlert}
            />

            <DisplayDocuments
              file={investor?.certificateOfRegistration}
              label={'Certificate of Registration'}
              setAlert={setAlert}
            />
          </DocumentsWrapper>

          <DocumentsWrapper>
            <DisplayDocuments
              file={investor?.taxPinCertificate}
              label={'Tax Pin Certificate'}
              setAlert={setAlert}
            />

            {investor?.letterOfAuthorization ? (
              <DisplayDocuments
                file={investor?.letterOfAuthorization}
                label={'Letter of Authorization'}
                setAlert={setAlert}
              />
            ) : (
              <DisplayDocuments
                file={investor?.boardResolutionLetter}
                label={'Board Resolution Letter'}
                setAlert={setAlert}
              />
            )}
          </DocumentsWrapper>

          <DocumentsWrapper>
            {investor?.letterOfAuthorization ? (
              <DisplayDocuments
                file={investor?.boardResolutionLetter}
                label={'Board Resolution Letter'}
                setAlert={setAlert}
              />
            ) : null}

            <DisplayDocuments
              file={investor?.CR12Certificate}
              label={'CR 12 Certificate'}
              setAlert={setAlert}
            />
          </DocumentsWrapper>
        </div>

        {investor?.kycStatus === kycStatuses.PENDING ||
        investor?.kycStatus === kycStatuses.REJECTED ||
        investor?.kycStatus === kycStatuses.IN_EDIT ? (
          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Previous"
              handleOnClick={handlePreviousButtonClick}
              twinButton
            />

            <ConfirmButton
              buttonText={
                investor?.kycStatus === kycStatuses.IN_EDIT ||
                investor?.kycStatus === kycStatuses.REJECTED
                  ? 'Update'
                  : 'Complete'
              }
              loading={loading}
              handleOnClick={handleButtonClick}
              twinButton
            />
          </ButtonsContainer>
        ) : null}
      </KycPageContentWrapper>
    );
  };

  const kycEntryPointUrl = localStorage.getItem('kycEntryPointUrl');

  return (
    <div className="mt-5">
      <Back
        title={`${capitalizeTheFirstLetterOfEachWord(
          investor?.investorType,
        )} Information`}
        onClick={() => {
          investorInformationSummaryPage &&
            localStorage.setItem('kycEntryPointUrl', kycEntryPointUrl);
          history.goBack();
        }}
      />

      {displayConfirmPage ? renderConfirmation() : renderDetails()}
      <KycChangeReasonModal
        openReasonModal={openReasonModal}
        setOpenReasonModal={setOpenReasonModal}
        reason={reason}
        setReason={setReason}
        sendKYCupdateRequest={sendKYCupdateRequest}
      />

      <CommonSuccessModal
        open={openSuccessModal}
        setOpen={() => {
          localStorage.removeItem('previousPage');
          setOpenSuccessModal(false);
        }}
        content={<KycCompletedModalContent userIsUpdating={!submittingKyc} />}
        handleButtonClick={handleHide}
        btnText={'Invest'}
        moreClasses={'bg-white'}
      />

      <CommonSuccessModal
        open={openPermissionModal}
        setOpen={() => setOpenPermissionModal(false)}
        content={<PermissionModalContent />}
        moreClasses={'bg-white'}
        title={'Notification'}
        handleButtonClick={() => setOpenPermissionModal(false)}
        addCloseButton={true}
      />
    </div>
  );
};
export default BusinessOverview;
