import { useFormik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { sendReferralCode } from '../_redux/referralActions';
import * as referral from '../_redux/referralReducer';

import Alert from '../../Common/Alert';
import CustomInput from '../../Common/CustomInput';

import '../index.scss';
import { ConfirmButton } from '../../Common/ReusableButtons';
import useUserProfile from '../../../helpers/CustomHook/useUserProfile';
import { ConfirmTransactionDetailsPageWrapper } from '../../Settlements/components/ConfirmTransactionsDetailsPageWrapper';

const EnterReferralCode = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const { updateInvestorProfile } = useUserProfile();

  const initialValues = {
    referralCode: '',
  };

  const MoveToTheFirstTab = () => {
    updateInvestorProfile();
    history.push('/referral-promo');
  };

  const referralCodeSchema = Yup.object().shape({
    referralCode: Yup.string()
      .min(8, 'Minimum 8 digits')
      .max(8, 'Maximum 8 digits')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: referralCodeSchema,
    onSubmit: async values => {
      setLoading(true);
      const referralCodeUpperCase = values.referralCode.toUpperCase();

      try {
        const response = await sendReferralCode(referralCodeUpperCase);

        if (response.status === 200) {
          setLoading(false);
          formik.resetForm();

          setAlert({
            alertMessage: 'Your referral code has been applied successfully',
            alertMessageType: 'success',
          });

          setTimeout(MoveToTheFirstTab, 3000);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
      }
    },
  });

  return (
    <>
      <ConfirmTransactionDetailsPageWrapper
        title={'Enter Code'}
        classes={'mt-10'}
      >
        {alertOn ? <div className="mb-5"></div> : null}

        <div>
          <div className="width-345 mt-3 font-size-16 text-center mx-auto">
            Have you been invited by another user? Use their referral code to
            earn a bonus.
          </div>

          <div className="form-group fv-plugins-icon-container m-10">
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework px-md-20 px-lg-40"
            >
              <CustomInput
                type="text"
                name="referralCode"
                formik={formik}
                required={true}
                placeholder={'Enter Code'}
                classes="mx-auto"
              />

              {alert?.alertMessage ? (
                <div className="mt-2">
                  <Alert alert={alert} />{' '}
                </div>
              ) : null}

              <div className="text-center my-5">
                <ConfirmButton
                  buttonType={'submit'}
                  buttonText={'Apply Code'}
                  buttonWidth={'width-345'}
                  disable={
                    !formik?.values?.referralCode ||
                    formik?.values?.referralCode?.length < 8
                  }
                  loading={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </ConfirmTransactionDetailsPageWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  referral: state.referral,
});

export default connect(mapStateToProps, referral.actions)(EnterReferralCode);
