import React from 'react';

export const KycCompletedModalContent = ({ userIsUpdating }) => {
  return (
    <>
      {userIsUpdating ? (
        <>
          You have successfully updated your information.  You can now invest.
        </>
      ) : (
        <>
          You have successfully submitted your information.  You can now invest.
        </>
      )}
    </>
  );
};
