import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { subscriptionCopy } from '../../utils/subscriptionCopy';
import Alert from '../Common/Alert';
import Back from '../Common/BackArrow';
import Loader from '../Common/Loader';
import ConfirmDowngrade from './ConfirmDowngrade';
import { fetchSubscriptions } from './_redux/subscriptionActions';
import Subscription from './subscription';

const Subscriptions = () => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [subscriptions, setSubscriptions] = useState();
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const { investor } = useSelector(state => state.profile);

  useEffect(() => {
    const getSubscriptions = async () => {
      try {
        setLoading(true);
        const subscriptionsResults = await fetchSubscriptions();
        const { data } = subscriptionsResults?.data;
        setLoading(false);
        setSubscriptions(data);
      } catch (error) {
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: error?.response?.data?.message,
          alertMessageType: 'error',
        });
      }
    };

    getSubscriptions();
  }, []);

  const previousPage = localStorage.getItem('previousPage');

  return (
    <>
      <Back title="Account Plans" previousPageLink={previousPage} />
      {alertOn ? <Alert alert={alert} /> : null}
      {confirm ? (
        <ConfirmDowngrade
          subscription={subscriptions.find(item => item.id === 1)}
          setConfirm={setConfirm}
          setAlertOn={setAlertOn}
          setAlert={setAlert}
        />
      ) : loading ? (
        <Loader />
      ) : (
        <div className="card card-custom p-3 p-md-10">
          <div className="border-1-light-blue-150 border-radius-16 p-1">
            {subscriptions && (
              <div className="mx-5 my-10 text-center font-weight-500">
                {subscriptionCopy?.description}
              </div>
            )}
            <div className="row text-center px-md-1">
              {subscriptions &&
                subscriptions.length > 0 &&
                subscriptions.map(subscription => (
                  <Subscription
                    key={subscription.id}
                    subscription={subscription}
                    setAlert={setAlert}
                    setAlertOn={setAlertOn}
                    setConfirm={setConfirm}
                    currentPlan={
                      subscription?.id === investor?.subscription?.id
                    }
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscriptions;
