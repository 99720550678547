import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import {
  accountTypes,
  countryCode,
  countryName,
  letterNumberRegex,
  specialCharError,
} from '../../../helpers/constants';

import {
  registerBusinessChama,
  registerBusinessChamaAfterRegistration,
} from '../_redux/authCrud';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import Dropdown from '../../Common/Dropdown';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import getCountries from '../../../helpers/countries';
import { checkForErrors } from '../../../helpers/checkForErrors';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import CustomInput from '../../Common/CustomInput';
import { ConfirmButton } from '../../Common/ReusableButtons';

const AccountDetails = intl => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openCountryDropdown, setOpenCountryDropdown] = useState(false);
  const [errorExist, setErrorExist] = useState(false);

  const profile = useSelector(state => state.profile);

  const { investor, user, accessToken } = profile;
  const userHasSignedInWithGoogle = user?.hasSignedInWithGoogle;

  const dispatch = useDispatch();

  const accountType = localStorage.getItem('accountType');

  const isBusinessAccount =
    accountType?.toLocaleLowerCase() ===
    accountTypes.BUSINESS.toLocaleLowerCase();

  const isChamaAccount =
    accountType?.toLocaleLowerCase() === accountTypes.CHAMA.toLocaleLowerCase();

  const [selectedCountry, setSelectedCountry] = useState({
    countryFlag: isChamaAccount
      ? `flag-icon-${countryCode.KE?.toLocaleLowerCase()}`
      : null,
    currency: isChamaAccount ? countryName?.KENYA : null,
    countryCode: isChamaAccount ? countryCode.KE : null,
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleCountryDropdown = () => {
    setOpenCountryDropdown(!openCountryDropdown);
  };

  const initialValues = {
    businessChamaName: investor?.businessChamaName || '',
    country: isChamaAccount ? countryCode.KE : null,
  };

  const DetailsSchema = Yup.object().shape({
    businessChamaName: Yup.string()
      .min(4, 'Minimum 4 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(letterNumberRegex, specialCharError)
      .required('Account Name is required'),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleSelect = country => {
    setSelectedCountry({
      countryFlag: country?.flag,
      currency: country?.name,
      countryCode: country?.countryCode,
    });
    setOpenCountryDropdown(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: DetailsSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const noErrors = checkForErrors({
          ...values,
          country: selectedCountry?.countryCode,
        });

        if (!noErrors) {
          setErrorExist(true);
          setLoading(false);
          return;
        }

        enableLoading();

        if (user?.id) {
          const combinedData = {
            userId: user?.id,
            businessChama: {
              businessChamaName: values.businessChamaName,
              country: selectedCountry?.countryCode,
              investorType: accountTypes[accountType?.toUpperCase()],
            },
          };

          //Save token in localstorage before making a call to the BE
          accessToken && localStorage.setItem('ndovuToken', accessToken);
          const creatingBusinessOrChamaAfterRegistration = userHasSignedInWithGoogle
            ? true
            : false;

          let res;
          if (creatingBusinessOrChamaAfterRegistration) {
            const businessChamaDetails = {
              businessChamaName: values.businessChamaName,
              country: selectedCountry?.countryCode,
              investorType: accountTypes[accountType?.toUpperCase()],
            };

            res = await registerBusinessChama(businessChamaDetails);
          } else {
            res = await registerBusinessChamaAfterRegistration(combinedData);
          }

          setStatus('Registration is successful, verify your email to login.');

          if (userHasSignedInWithGoogle) {
            delete profile.accessToken;
            delete profile.idToken;

            dispatch(
              investorProfileActions.profileAction({
                ...profile,
                investor: res.data?.data?.investor,
              }),
            );

            localStorage.setItem('ndovuToken', res.data?.data?.accessToken);

            history.push('/home');
          } else {
            history.push('/auth/success');
          }
        } else {
          // Handle any other response status codes or errors
          setStatus('Registration failed, please try again');
        }
      } catch (error) {
        // Handle exceptions or errors here, e.g., set an error message with setStatus
        setStatus(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Registration failed, please try again',
        );
        disableLoading();
      }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-5 mb-lg-10">
        <ExternalPageTitle
          title={`${capitalizeTheFirstLetterOfEachWord(
            accountType,
          )} Investor Details`}
        />

        <form
          className="form fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={formik.handleSubmit}
        >
          {formik.status ? (
            <div className="mb-10 ">
              <div
                className="text-center font-weight-bold"
                style={{ color: '#F05B7F' }}
              >
                {formik.status}
              </div>
            </div>
          ) : (
            ''
          )}

          <CustomInput
            type="text"
            title={isBusinessAccount ? 'Business Name' : 'Chama Name'}
            placeholder={
              isBusinessAccount ? 'Enter Business Name' : 'Enter Chama Name'
            }
            name="businessChamaName"
            formik={formik}
            getInputClasses={getInputClasses}
            required={true}
            inputWidth={'auth-page-input-container'}
          />

          <div className="select-input-auth-page center-input mt-7">
            <DropdownTitle title={'Select Country'} />

            <Dropdown
              handleOpenSelector={handleCountryDropdown}
              dropdownPlaceholder={'Select Country'}
              selectedOption={selectedCountry}
              classes={'auth-page-input-container custom-input-width pr-5 pl-2'}
              name={'selectedCountry'}
              disableDropdown={isChamaAccount}
            />

            {errorExist ? (
              <FieldErrorMessage
                errorExist={errorExist}
                errorMessage={`${'Country is Required'}`}
                fieldValue={selectedCountry?.countryCode}
              />
            ) : null}
          </div>

          <ConfirmButton
            buttonText="Continue"
            loading={loading}
            buttonWidth={'w-100'}
            classes={'d-flex justify-content-center align-items-center mt-8'}
            buttonType={'submit'}
          />
        </form>
      </div>

      <SelectOptionsModal
        open={openCountryDropdown}
        handleClose={() => setOpenCountryDropdown(false)}
        allCountries={getCountries()}
        handleSelect={handleSelect}
        placeholder={'Select Country'}
        selectedCountry={selectedCountry}
      />
    </div>
  );
};

export default AccountDetails;
