import React from 'react';

export const ConfirmTransactionDetailsPageWrapper = ({
  children,
  title,
  classes,
}) => {
  return (
    <>
      <div
        className={`${classes} border-light-blue-600 border-radius-8 kyc-content-wrapper mx-auto`}
      >
        <div className="border-b-light-blue-545 text-14 font-weight-600 text-center py-8">
          {title}
        </div>
        <div className="pt-6 pb-sm-10 pb-5 px-5 px-sm-10">{children}</div>
      </div>
    </>
  );
};
