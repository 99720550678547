import elephantLogo from '../../../../images/elephantLogo.svg';

import conservativeInvestorImage from '../../../../images/riskProfile/ConservativeInvestorImage.svg';
import balancedInvestorImage from '../../../../images/riskProfile/BalancedInvestorImage.svg';
import aggressiveInvestorImage from '../../../../images/riskProfile/AggressiveInvestorImage.svg';

const DisplayImage = riskProfileId => {
  let image;
  let elephant;
  let name;
  if (riskProfileId === 1) {
    elephant = elephantLogo;
    image = conservativeInvestorImage;
    name = 'Warren Buffett';
  }

  if (riskProfileId === 3) {
    elephant = elephantLogo;
    image = aggressiveInvestorImage;
    name = 'Elon Musk';
  }

  if (riskProfileId === 2) {
    elephant = elephantLogo;
    image = balancedInvestorImage;
    name = 'Shawn Corey Carter (Jay Z)';
  }

  return { elephant, image, name };
};

export default DisplayImage;
