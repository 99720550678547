import referralIcon from '../../../../../app/icons/RightMenuIcons/referalIcon.svg';
import referralWhiteIcon from '../../../../../app/icons/RightMenuIcons/referalWhiteIcon.svg';

import HelpWhite from '../../../../../app/icons/LeftMenuIcons/help-white.svg';
import Help from '../../../../../app/icons/LeftMenuIcons/help.svg';
import ManageAccountWhite from '../../../../../app/icons/LeftMenuIcons/manage-account-white.svg';
import ManageAccount from '../../../../../app/icons/LeftMenuIcons/manage-account.svg';
import TransactionWhite from '../../../../../app/icons/LeftMenuIcons/transaction-white.svg';
import Transaction from '../../../../../app/icons/LeftMenuIcons/transaction.svg';
import Community from '../../../../../app/icons/LeftMenuIcons/community.svg';
import CommunityWhite from '../../../../../app/icons/LeftMenuIcons/communityWhite.svg';

const rightSideMenuList = [
  {
    title: 'Manage Account',
    name: 'manageAccount',
    path: '/manage-account',
    icon: { white: ManageAccountWhite, orange: ManageAccount },
    active: false,
    hover: false,
    eventName: 'Click_Manage_Account',
  },
  {
    title: 'Community',
    name: 'community',
    externalLink: true,
    path: process.env.REACT_APP_SOFIA_COMMUNITY_URL,
    icon: { white: CommunityWhite, orange: Community },
    active: false,
    hover: false,
    newItem: 'NEW',
    eventName: 'Click_Community',
  },
  {
    title: 'Referral Promo',
    name: 'referralPromo',
    path: '/referral-promo',
    icon: { white: referralWhiteIcon, orange: referralIcon },
    active: false,
    hover: false,
    eventName: 'Click_Referral_Promo',
  },
  {
    title: 'Statements and Transactions',
    name: 'transactionsHistory',
    path: '/transaction',
    icon: { white: TransactionWhite, orange: Transaction },
    active: false,
    hover: false,
    eventName: 'Click_Statements_and_Transactions',
  },
  {
    title: 'FAQ',
    name: 'faq',
    externalLink: true,
    path: 'https://ndovu.co/ndovu-faqs',
    icon: { white: HelpWhite, orange: Help },
    active: false,
    hover: false,
  },
];

export default rightSideMenuList;
