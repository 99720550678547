import React from 'react';
import { useHistory } from 'react-router-dom';
import { firstLetterUpperCase } from '../../../../helpers/investmentHelpers';
import Card from './Card';
import TopCard from './TopCard';
import ProfileRisk from './ProfileRisk';

import './index.scss';
import { PlanTitle } from '../../../Common/PlanTitle';
import { trackEventMixpanel } from '../../../../eventTracking/mixpanel';

const PlanCard = ({ data, planTypeName }) => {
  const history = useHistory();

  const redirectToPlanDetails = () => {
    trackEventMixpanel(`Product_Click_${data?.coolName}`);
    localStorage.setItem('planEntryPointUrl', history.location.pathname);
    history.push(`/plans/${data?.id}`);
  };

  return (
    <Card width={'plan-card-width'}>
      <PlanTitle
        className={'plan-title px-3'}
        data={data}
        planTypeName={planTypeName}
      />

      {data && data.imageUrl ? (
        <TopCard onClick={redirectToPlanDetails} imageLink={data.imageUrl}>
          <span className="cash text-blue">
            {firstLetterUpperCase(data?.sector)}
          </span>
        </TopCard>
      ) : (
        <TopCard onClick={() => redirectToPlanDetails}>
          <span className="cash text-blue">
            {firstLetterUpperCase(data?.sector)}
          </span>
        </TopCard>
      )}

      <div
        className="plan-card-second-container pb-2"
        onClick={redirectToPlanDetails}
      >
        <div
          className="d-flex justify-content-between align-items-center px-2"
          onClick={() => redirectToPlanDetails}
          style={{ height: '49px' }}
        >
          <div className="d-flex">
            <span
              className="text-dark font-weight-bold td-font-size-14"
              style={{ width: '150px', paddingTop: '10px' }}
            >
              {data?.hint}
            </span>
          </div>

          <div className="d-flex flex-column align-items-end">
            <span
              className="font-weight-600 text-20"
              style={{
                paddingTop: '7px',
                marginBottom: '-12px',
              }}
            >
              {data?.performance1 > 0 ? (
                <>
                  <span className="text-yellow">+</span>
                  {Math.abs(data?.performance1)}%
                </>
              ) : data?.performance1 < 0 ? (
                <>
                  <span className="text-yellow">-</span>
                  {Math.abs(data?.performance1)}%
                </>
              ) : (
                <span>---</span>
              )}
            </span>
            <span className="p-1 text-dark font-weight-bold pl-md-5 td-font-size-14">
              {data?.profitTypeDescription}
            </span>
          </div>
        </div>

        <div className="fund-container"></div>

        <ProfileRisk
          planTypeName={planTypeName}
          riskProfile={data?.planRiskLevel}
          data={data}
        />
      </div>
    </Card>
  );
};

export default PlanCard;
