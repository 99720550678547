import React from 'react';

const ExtremelyLowRisk = ({ squareSize, extremelyLowSquareWidth }) => {
  const squares = [0, 1, 2, 3, 4, 5];
  return (
    <div className="d-flex justify-content-end">
      <span
        className="risk-profile-tiny-square border-radius-left-side blue "
        style={{ height: squareSize, width: extremelyLowSquareWidth }}
      ></span>
      <span
        className="risk-profile-tiny-square border-radius-right-side"
        style={{ height: squareSize, width: extremelyLowSquareWidth }}
      ></span>

      {squares.map(item => (
        <span
          key={item}
          className="risk-profile-little-square gray ml-1"
          style={{ width: squareSize, height: squareSize }}
        ></span>
      ))}
    </div>
  );
};

export default ExtremelyLowRisk;
