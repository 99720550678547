export const actionTypes = {
  IdType: '[IdType] Action',
  IdentificationNumber: '[IdentificationNumber] Action',
  Front: '[Front] Action',
  TaxPinCertificate: '[TaxPinCertificate] Action',
  TaxPayerNumber: '[TaxPayerNumber] Action',
  UpdateKycInfo: '[UpdateKycInfo] Action',
  CleanKycSate: '[CleanKycSate] Action',
};

const initialKycInfoState = {};

export const reducer = (state = initialKycInfoState, action) => {
  switch (action.type) {
    case actionTypes.IdType: {
      const { identificationType } = action.payload;
      return { ...state, identificationType };
    }
    case actionTypes.IdentificationNumber: {
      const { identificationNumber } = action.payload;
      return { ...state, identificationNumber };
    }
    case actionTypes.Front: {
      const { front } = action.payload;
      return { ...state, front };
    }
    case actionTypes.TaxPinCertificate: {
      const { taxPinCertificate } = action.payload;
      return { ...state, taxPinCertificate };
    }
    case actionTypes.TaxPayerNumber: {
      const { taxPayerNumber } = action.payload;
      return { ...state, taxPayerNumber };
    }

    case actionTypes.UpdateKycInfo: {
      const payload = action.payload;
      return { ...state, ...payload };
    }

    case actionTypes.CleanKycSate: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  identificationType: identificationType => ({
    type: actionTypes.IdType,
    payload: { identificationType },
  }),

  identificationNumber: identificationNumber => ({
    type: actionTypes.IdentificationNumber,
    payload: { identificationNumber },
  }),

  front: front => ({
    type: actionTypes.Front,
    payload: { front },
  }),

  taxPinCertificate: taxPinCertificate => ({
    type: actionTypes.TaxPinCertificate,
    payload: { taxPinCertificate },
  }),

  taxPayerNumber: taxPayerNumber => ({
    type: actionTypes.TaxPayerNumber,
    payload: { taxPayerNumber },
  }),

  updateKycInfo: payload => ({
    type: actionTypes.UpdateKycInfo,
    payload: payload,
  }),

  cleanKycState: () => ({
    type: actionTypes.CleanKycSate,
  }),
};

export function* saga() {}
