import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';

import { capitalizeTheFirstLetterOfEachWord } from '../../helpers/investmentHelpers';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

export const DetailsItem = ({
  value,
  label,
  labelAdditionalClasses,
  valueAdditionalClasses,
  changeValueString = true,
  addCopyIcon,
}) => {
  const [itemValue, setItemValue] = useState();

  useEffect(() => {
    const valueText =
      value && changeValueString
        ? capitalizeTheFirstLetterOfEachWord(value)
        : value
        ? value
        : '---';

    setItemValue(valueText);
  }, [value, changeValueString]);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(itemValue);
  };
  return (
    <div className="d-flex justify-content-between py-1 text-12">
      <span className={`${labelAdditionalClasses}`}>{label}</span>
      <div className="d-flex align-items-center">
        <span className={`font-weight-600 ml-5 mr-2 ${valueAdditionalClasses}`}>
          {itemValue}
        </span>
        {addCopyIcon ? (
          <div onClick={handleCopy} className="cursor-pointer">
            <SVG src={toAbsoluteUrl('/media/svg/ndovu/copy.svg')} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
