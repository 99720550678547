import React from 'react';
import PopoverComponent from '../../../Common/PopoverComponent';

const PlanTypeComponent = ({ planTypeName, popoverContent }) => {
  return (
    <div>
      <span className="p-1 text-dark pl-md-5 text-13">{planTypeName}</span>
      {popoverContent ? (
        <PopoverComponent popoverContent={popoverContent}>
          <span className="cursor-pointer">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="12" fill="#0071CE" />
              <path
                d="M11.9895 5.7952C13.351 5.7952 14.4441 6.16829 15.2688 6.91447C16.1066 7.64756 16.5255 8.66211 16.5255 9.95811C16.5255 11.241 16.1132 12.2228 15.2885 12.9036C14.4637 13.5712 13.3706 13.905 12.0092 13.905L11.9306 15.417H9.51536L9.41718 12.0788H10.3008C11.4397 12.0788 12.3168 11.9283 12.9321 11.6272C13.5474 11.3261 13.855 10.7763 13.855 9.97775C13.855 9.40175 13.6848 8.94356 13.3445 8.6032C13.0172 8.26284 12.5655 8.09266 11.9895 8.09266C11.3874 8.09266 10.9161 8.25629 10.5757 8.58356C10.2485 8.91084 10.0848 9.36247 10.0848 9.93847H7.49281C7.47972 9.13993 7.64991 8.42647 8.00336 7.79811C8.35681 7.16975 8.87391 6.67884 9.55463 6.32538C10.2485 5.97193 11.0601 5.7952 11.9895 5.7952ZM10.7132 20.1101C10.2157 20.1101 9.80336 19.9596 9.47609 19.6585C9.16191 19.3443 9.00481 18.9581 9.00481 18.4999C9.00481 18.0417 9.16191 17.6621 9.47609 17.361C9.80336 17.0468 10.2157 16.8897 10.7132 16.8897C11.1975 16.8897 11.5968 17.0468 11.911 17.361C12.2252 17.6621 12.3823 18.0417 12.3823 18.4999C12.3823 18.9581 12.2252 19.3443 11.911 19.6585C11.5968 19.9596 11.1975 20.1101 10.7132 20.1101Z"
                fill="white"
              />
            </svg>
          </span>
        </PopoverComponent>
      ) : null}
    </div>
  );
};

export default PlanTypeComponent;
