import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Country } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';

import '../components/index.scss';
import FileUpload from '../helpers/FileUpload';
import CustomInput from '../../Common/CustomInput';

import {
  idTypeOptions,
  letterNumberRegex,
  specialCharError,
  accountTypes,
  idTypes,
  businessTypes,
  businessType,
  kycStatuses,
  countryName,
  countryCode,
} from '../../../helpers/constants';

import { submitbuschamaKYC } from '../_redux/busChamaCrud';
import { downloadUsersFile } from '../../Profile/_redux/profileCrud';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import {
  compressImage,
  generateImageDataUrl,
  isFileNameValid,
  theFileIsLessThanOrEqualToMaximumSize,
  validateFile,
} from '../../../helpers/validateFiles';

import { UploadedFile } from '../../Business/helpers/UploadedFile';
import { ValidationErrors } from '../components/ValidationErrors';
import getCountries from '../../../helpers/countries';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import Dropdown from '../../Common/Dropdown';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import { checkForErrors } from '../../../helpers/checkForErrors';
import { getCountryCode } from '../../../helpers/getCountryCode';
import ReusableDropdown from '../../Common/ReusableDropdown';
import OptionsModal from '../../Common/OptionsModal';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const VerifyBusinessDocuments = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  const {
    id: stepperId,
    handleStepChange,
    profile: { investor },
  } = props;

  const profile = useSelector(state => state.profile);

  const businessCountry = localStorage.getItem('businessCountry')
    ? localStorage.getItem('businessCountry')
    : investor?.country;

  const businessAccountIsKenya =
    businessCountry === countryName.KENYA || businessCountry === countryCode.KE;

  const accountType = localStorage.getItem('accountType')
    ? localStorage.getItem('accountType')
    : investor?.investorType;

  const isChamaAccount =
    accountType?.toLocaleLowerCase() === accountTypes.CHAMA.toLocaleLowerCase();

  const isBusinessAccount =
    accountType?.toLocaleLowerCase() ===
    accountTypes.BUSINESS.toLocaleLowerCase();

  const allCountries = getCountries();

  const [openCountryDropdown, setOpenCountryDropdown] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openBusinessTypeDropdown, setOpenBusinessTypeDropdown] = useState(
    false,
  );

  const [fieldsError, setFieldsError] = useState();
  const [errorExist, setErrorExist] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  const [selectedIdType, setSelectedIdType] = useState(
    investor?.businessChamaDirectorIdentity?.businessChamaAdminIdentityType
      ? {
          label:
            idTypeOptions[
              investor?.businessChamaDirectorIdentity?.businessChamaAdminIdentityType.toUpperCase()
            ],
          value: investor?.businessChamaDirectorIdentity?.businessChamaAdminIdentityType.toUpperCase(),
        }
      : {
          label: null,
          value: null,
        },
  );

  const [selectedBusinessType, setSelectedBusinessType] = useState(
    investor?.businessType
      ? {
          label: capitalizeTheFirstLetterOfEachWord(
            investor?.businessType?.replaceAll('_', ' '),
          ),
          value: investor?.businessType,
        }
      : { label: null, value: null },
  );

  const [loading, setLoading] = useState(false);

  const [selectedIdentificationID, setSelectedIdentificationID] = useState();
  const [identificationIdDataUrl, setIdentificationIdDataUrl] = useState();
  const [identificationIdError, setIdentificationIdError] = useState();

  const [error, setError] = useState();
  const [uploading, setUploading] = useState({
    treasurersDoc: false,
    certificateOfRegistration: false,
    authorisationLetter: false,
    identificationId: false,
    taxPinCertificate: false,
    CR12Certificate: false,
    boardResolutionLetter: false,
  });

  const [
    selectedCertificateRegistration,
    setSelectedCertificateRegistration,
  ] = useState();

  const [
    certificateRegistrationDataUrl,
    setCertificateRegistrationDataUrl,
  ] = useState();

  const [
    certificateRegistrationError,
    setCertificateRegistrationError,
  ] = useState();

  const [selectedTaxPinCertificate, setSelectedTaxPinCertificate] = useState();
  const [taxPinCertificateError, setTaxPinCertificateError] = useState();
  const [
    taxPinCertificateImageDataUrl,
    setTaxPinCertificateImageDataUrl,
  ] = useState();

  const [selectedCR12Certificate, setSelectedCR12Certificate] = useState();
  const [CR12CertificateError, setCR12CertificateError] = useState();
  const [
    CR12CertificateImageDataUrl,
    setCR2CertificateImageDataUrl,
  ] = useState();

  const [
    selectedBoardResolutionLetter,
    setSelectedBoardResolutionLetter,
  ] = useState();

  const [
    boardResolutionLetterError,
    setBoardResolutionLetterError,
  ] = useState();

  const [
    boardResolutionDataImageUrl,
    setBoardResolutionDataImageUrl,
  ] = useState();

  const [
    selectedAuthorizationLetter,
    setSelectedAuthorizationLetter,
  ] = useState();

  const [authorizationLetterError, setAuthorizationLetterError] = useState();
  const [
    authorizationLetterImageDataUrl,
    setAuthorizationLetterImageDataUrl,
  ] = useState();

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleCountryDropdown = () => {
    setOpenCountryDropdown(!openCountryDropdown);
  };

  const updateCountryDetails = async () => {
    const countryCodeOrName = investor?.businessChamaDirectorCountry
      ? investor?.businessChamaDirectorCountry?.toLowerCase()
      : '';

    const { countryCode } = await getCountryCode(countryCodeOrName);

    setSelectedCountry({
      countryFlag: countryCode
        ? `flag-icon-${countryCode?.toLowerCase()}`
        : null,
      currency: countryCode
        ? Country.getCountryByCode(countryCode)?.name
        : null,
      countryCode: countryCode ? countryCode : null,
    });
  };

  useEffect(() => {
    handleStepChange({ next: stepperId });
    updateCountryDetails();

    if (businessAccountIsKenya) {
      formik.setFieldValue('businessChamaAdminIdentityType', 'PASSPORT');
    } else {
      formik.setFieldValue('businessChamaAdminIdentityType', 'NATIONAL_ID');
    }
  }, []);

  const initialValues = {
    businessChamaDirectorName: investor?.businessChamaDirectorName || '',
    businessChamaDirectorIdNumber:
      investor?.businessChamaDirectorIdNumber || '',
    businessChamaRegistrationNumber:
      investor?.businessChamaRegistrationNumber || '',
    taxPayerNumber: investor?.taxPayerNumber || '',
  };

  const initialChamaValues = {
    businessChamaDirectorName: investor?.businessChamaDirectorName || '',
    businessChamaDirectorIdNumber:
      investor?.businessChamaDirectorIdNumber || '',
    taxPayerNumber: investor?.taxPayerNumber || '',
    businessChamaRegistrationNumber:
      investor?.businessChamaRegistrationNumber || '',
  };

  // Validation schema for CHAMA
  const ChamaValidationSchema = Yup.object().shape({
    businessChamaDirectorName: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Treasurer’s Name is required'),
    businessChamaDirectorIdNumber: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Treasurer’s ID Number is required'),
    taxPayerNumber: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('KRA PIN Number is required'),
    businessChamaRegistrationNumber: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Chama Registration Number is required'),
  });

  // Validation schema for BUSINESS
  const BusinessValidationSchema = Yup.object().shape({
    businessChamaDirectorName: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Director’s Name is required'),
    businessChamaDirectorIdNumber: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Director’s ID Number is required'),
    taxPayerNumber: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('KRA PIN Number is required'),
    businessChamaRegistrationNumber: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Business Registration Number is required'),
  });

  const closeCountryDropdown = () => {
    setOpenCountryDropdown(false);
  };

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleSelect = country => {
    setFieldsError({ ...fieldsError, country: null });
    setSelectedCountry({
      countryFlag: country?.flag,
      currency: country?.name,
      countryCode: country?.countryCode,
    });
    closeCountryDropdown();
  };

  const handleSelectIdType = item => {
    setSelectedIdType(item);
    closeDropdown();
  };

  const handleBusinessTypeChange = item => {
    setSelectedBusinessType(item);
    setOpenBusinessTypeDropdown(false);
  };

  const FILE_SIZE = 5 * 1024 * 1024;

  const handleCardUpload = async (e, name) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (name === 'businessChamaDirectorIdentity') {
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setIdentificationIdError(result?.error);
        return;
      }

      setUploading({ ...uploading, identificationId: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setIdentificationIdError(validationError?.error);
        setUploading({ ...uploading, identificationId: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setIdentificationIdError('ID should be Max 5MB');
          setUploading({ ...uploading, identificationId: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setIdentificationIdError('ID should be Max 5MB');
        setUploading({ ...uploading, identificationId: false });
        return;
      }

      setSelectedIdentificationID(newFile);
      formik.setFieldValue(name, newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setIdentificationIdDataUrl(fileDataUrl);
          setUploading({ ...uploading, identificationId: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ ...uploading, identificationId: false });
        });

      setUploading({ ...uploading, identificationId: false });
      setIdentificationIdError(null);
      return;
    }

    if (name === 'certificateOfRegistration') {
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setCertificateRegistrationError(result?.error);
        return;
      }

      setUploading({ ...uploading, certificateOfRegistration: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setCertificateRegistrationError(validationError?.error);
        setUploading({ certificateOfRegistration: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setCertificateRegistrationError('Certificate should be Max 5MB');
          setUploading({ certificateOfRegistration: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setCertificateRegistrationError('Certificate should be Max 5MB');
        setUploading({ certificateOfRegistration: false });
        return;
      }

      setSelectedCertificateRegistration(newFile);
      formik.setFieldValue(name, newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setCertificateRegistrationDataUrl(fileDataUrl);
          setUploading({ taxPinCertificate: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ taxPinCertificate: false });
        });

      setCertificateRegistrationError(null);
      return;
    }

    if (name === 'authorisationLetter') {
      setUploading({ ...uploading, authorisationLetter: true });

      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setAuthorizationLetterError(result?.error);
        setUploading({ ...uploading, authorisationLetter: false });
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setAuthorizationLetterError(validationError?.error);
        setUploading({ ...uploading, authorisationLetter: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setAuthorizationLetterError('Authorisation Letter should be Max 5MB');
          setUploading({ ...uploading, taxPinCertificate: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setAuthorizationLetterError('Authorisation Letter should be Max 5MB');
        setUploading({ ...uploading, taxPinCertificate: false });
        return;
      }

      setSelectedAuthorizationLetter(newFile);
      formik.setFieldValue(name, newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setAuthorizationLetterImageDataUrl(fileDataUrl);
          setUploading({ taxPinCertificate: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ taxPinCertificate: false });
        });

      setAuthorizationLetterError(null);
      return;
    }

    if (name === 'taxPinCertificate') {
      setUploading({ taxPinCertificate: true });

      // Validate File name
      setSelectedTaxPinCertificate(null);
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setTaxPinCertificateError(result?.error);
        setUploading({ taxPinCertificate: false });
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setTaxPinCertificateError(validationError?.error);
        setUploading({ taxPinCertificate: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setTaxPinCertificateError(
            `${
              !businessAccountIsKenya
                ? 'Tax Certificate or Equivalent'
                : 'Tax Pin Certificate'
            } should be Max 5MB`,
          );
          setUploading({ taxPinCertificate: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setTaxPinCertificateError(
          `${
            !businessAccountIsKenya
              ? 'Tax Certificate or Equivalent'
              : 'Tax Pin Certificate'
          } should be Max 5MB`,
        );
        setUploading({ taxPinCertificate: false });
        return;
      }

      setSelectedTaxPinCertificate(newFile);
      formik.setFieldValue(name, newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setTaxPinCertificateImageDataUrl(fileDataUrl);
          setUploading({ proofOfAddress: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ proofOfAddress: false });
        });

      setTaxPinCertificateError(null);
      return;
    }

    if (name === 'CR12Certificate') {
      setUploading({ ...uploading, CR12Certificate: true });

      // Validate File name
      setCR12CertificateError(null);
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setCR12CertificateError(result?.error);
        setUploading({ ...uploading, CR12Certificate: false });
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setCR12CertificateError(validationError?.error);
        setUploading({ CR12Certificate: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setCR12CertificateError('CR2 Certificate should be Max 5MB');
          setUploading({ proofOfAddress: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setCR12CertificateError('CR2 Certificate should be Max 5MB');
        setUploading({ proofOfAddress: false });
        return;
      }

      setSelectedCR12Certificate(newFile);
      formik.setFieldValue(name, newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setCR2CertificateImageDataUrl(fileDataUrl);
          setUploading({ proofOfAddress: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ proofOfAddress: false });
        });

      setCR12CertificateError(null);
      return;
    }

    if (name === 'boardResolutionLetter') {
      setUploading({ ...uploading, boardResolutionLetter: true });
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setBoardResolutionLetterError(result?.error);
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setBoardResolutionLetterError(validationError?.error);
        setUploading({ boardResolutionLetter: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setBoardResolutionLetterError('Board Resolution should be Max 5MB');
          setUploading({ ...uploading, boardResolutionLetter: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setBoardResolutionLetterError('Board Resolution should be Max 5MB');
        setUploading({ ...uploading, boardResolutionLetter: false });
        return;
      }

      setSelectedBoardResolutionLetter(newFile);
      formik.setFieldValue(name, newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setBoardResolutionDataImageUrl(fileDataUrl);
          setUploading({ ...uploading, boardResolutionLetter: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ front: false });
        });

      setBoardResolutionLetterError(null);
      return;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isChamaAccount ? initialChamaValues : initialValues,
    validationSchema: isChamaAccount
      ? ChamaValidationSchema
      : BusinessValidationSchema,
  });

  const handleSubmit = async () => {
    setLoading(true);

    const businessFields = {
      businessChamaDirectorCountry: selectedCountry?.countryCode,
      businessChamaAdminIdentityType: selectedIdType?.value,
    };

    const businessFieldsForKenyan = {
      ...businessFields,
      businessType: selectedBusinessType?.value,
    };

    const chamaFields = {
      businessChamaDirectorCountry: selectedCountry?.countryCode,
      businessChamaAdminIdentityType: selectedIdType?.value,
    };

    const fields = isChamaAccount
      ? chamaFields
      : businessAccountIsKenya
      ? businessFieldsForKenyan
      : businessFields;

    const { values } = formik;

    const noErrors = checkForErrors({ ...values, ...fields });

    if (!noErrors) {
      setErrorExist(true);
      setLoading(false);
      return;
    }

    if (investor?.kycStatus === kycStatuses.SUBMITTED) {
      setError(
        'Investor information change is not allowed as your information verification is in progress.',
      );
      setLoading(false);
      return;
    }

    const formData = new FormData();

    // Common fields for both CHAMA and BUSINESS
    if (selectedTaxPinCertificate) {
      formData.append('taxPinCertificate', selectedTaxPinCertificate);
    } else if (!investor?.taxPinCertificate) {
      setLoading(false);
      setTaxPinCertificateError(
        `${
          !businessAccountIsKenya
            ? 'Tax Certificate or Equivalent'
            : 'Tax Pin Certificate'
        } is required`,
      );
      return;
    }

    if (selectedCertificateRegistration) {
      formData.append(
        'certificateOfRegistration',
        selectedCertificateRegistration,
      );
    } else if (!investor?.certificateOfRegistration) {
      setLoading(false);
      setCertificateRegistrationError(
        'Certificate of Registration is required',
      );
      return;
    }

    if (selectedIdentificationID) {
      formData.append(
        'businessChamaDirectorIdentity',
        selectedIdentificationID,
      );
    } else if (!investor?.businessChamaDirectorIdentity?.passportOrNationalId) {
      setLoading(false);
      setIdentificationIdError('ID is required');
      return;
    }

    selectedCountry?.countryCode &&
      formData.append(
        'businessChamaDirectorCountry',
        selectedCountry?.countryCode,
      );

    if (isChamaAccount) {
      // CHAMA-specific fields
      if (values.businessChamaAdminIdentityType) {
        formData.append(
          'businessChamaAdminIdentityType',
          values.businessChamaAdminIdentityType,
        );
      }

      if (values.businessChamaDirectorIdNumber) {
        formData.append(
          'businessChamaDirectorIdNumber',
          values.businessChamaDirectorIdNumber,
        );
      }

      if (values.businessChamaDirectorName) {
        formData.append(
          'businessChamaDirectorName',
          values.businessChamaDirectorName,
        );
      }

      if (values.businessChamaRegistrationNumber) {
        formData.append(
          'businessChamaRegistrationNumber',
          values.businessChamaRegistrationNumber,
        );
      }

      if (selectedAuthorizationLetter) {
        formData.append('letterOfAuthorization', selectedAuthorizationLetter);
      } else if (!investor?.letterOfAuthorization) {
        setLoading(false);
        setAuthorizationLetterError('Letter of Authorization is required');
        return;
      }

      if (values.taxPayerNumber) {
        formData.append('taxPayerNumber', values.taxPayerNumber);
      }
    } else if (isBusinessAccount) {
      // BUSINESS-specific fields
      if (values.businessChamaDirectorName) {
        formData.append(
          'businessChamaDirectorName',
          values.businessChamaDirectorName,
        );
      }

      if (selectedBusinessType?.value && businessAccountIsKenya) {
        formData.append('businessType', selectedBusinessType?.value);
      }

      if (selectedIdType?.value) {
        formData.append(
          'businessChamaAdminIdentityType',
          selectedIdType?.value,
        );
      }

      if (values.businessChamaDirectorIdNumber) {
        formData.append(
          'businessChamaDirectorIdNumber',
          values.businessChamaDirectorIdNumber,
        );
      }

      if (values.businessChamaDirectorCountry) {
        formData.append(
          'businessChamaDirectorCountry',
          values.businessChamaDirectorCountry,
        );
      }

      if (values.taxPayerNumber) {
        formData.append('taxPayerNumber', values.taxPayerNumber);
      }

      if (values.businessChamaRegistrationNumber) {
        formData.append(
          'businessChamaRegistrationNumber',
          values.businessChamaRegistrationNumber,
        );
      }

      if (values.directorsName) {
        formData.append('businessChamaDirectorName', values.directorsName);
      }

      if (selectedBoardResolutionLetter) {
        selectedBusinessType?.value === businessType.INCORPORATED_BUSINESS &&
          formData.append(
            'boardResolutionLetter',
            selectedBoardResolutionLetter,
          );
      } else if (
        selectedBusinessType?.value === businessType.INCORPORATED_BUSINESS &&
        !investor?.boardResolutionLetter
      ) {
        setBoardResolutionLetterError('Board Resolution Letter  is required');
        setLoading(false);
        return;
      }

      if (selectedCR12Certificate) {
        selectedBusinessType?.value === businessType.INCORPORATED_BUSINESS &&
          formData.append('CR12Certificate', selectedCR12Certificate);
      } else if (
        selectedBusinessType?.value === businessType.INCORPORATED_BUSINESS &&
        !investor?.CR12Certificate
      ) {
        setCR12CertificateError('CR12 Certificate  is required');
        setLoading(false);
        return;
      }
    }

    try {
      // Submit formData
      const {
        data: { data: investorInfo },
      } = await submitbuschamaKYC(formData);

      dispatch(
        investorProfileActions.profileAction({
          ...profile,
          investor: investorInfo,
          loading: false,
        }),
      );
      setLoading(false);
      history.push('/investor-information/additional');
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  const identificationType = `${
    selectedIdType?.label ? selectedIdType?.label : idTypeOptions.NATIONAL_ID
  }`;

  const idLabel = isChamaAccount
    ? `Treasurer’s ${identificationType} Number`
    : `Director’s ${identificationType} Number`;

  const handleOpeningFile = async link => {
    downloadUsersFile(link)
      .then(response => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      })
      .catch(() => {
        setError('Failed to download this document');
      });
  };

  const idTypePlaceHolder = `Select ${
    isChamaAccount ? 'Treasurer’s ID ' : 'Director’s ID '
  }
    Type`;

  const selectCountryPlaceholder = `Select ${
    isChamaAccount ? `Treasure's ` : 'Director ID '
  }
      Country`;

  return (
    <>
      <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
        <KycPageContentWrapper>
          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title={isChamaAccount ? 'Treasurer’s Name ' : 'Director’s Name '}
              name={'businessChamaDirectorName'}
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />
            <DropdownContainer>
              <DropdownTitle
                title={`${
                  isChamaAccount ? 'Treasurer’s ID ' : 'Director’s ID '
                } Type`}
              />

              <ReusableDropdown
                handleOpenSelector={handleDropdown}
                dropdownPlaceholder={idTypePlaceHolder}
                selectedOption={selectedIdType?.label}
                classes={'pr-5 pl-2'}
                name={'selectedIdType'}
              />

              {errorExist ? (
                <FieldErrorMessage
                  errorExist={errorExist}
                  errorMessage={`${
                    isChamaAccount ? `Treasure's ID Type ` : 'Director ID Type '
                  }is required`}
                  fieldValue={selectedIdType?.value}
                />
              ) : null}
            </DropdownContainer>{' '}
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title={idLabel}
              name={'businessChamaDirectorIdNumber'}
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />

            <DropdownContainer>
              <DropdownTitle title={'Country'} />

              <Dropdown
                handleOpenSelector={handleCountryDropdown}
                dropdownPlaceholder={selectCountryPlaceholder}
                selectedOption={selectedCountry}
                classes={'border-light-blue-400 border-radius-8 pr-5 pl-2'}
                name={'selectedCountry'}
              />

              {errorExist ? (
                <FieldErrorMessage
                  errorExist={errorExist}
                  errorMessage={`${
                    isChamaAccount ? `Treasure's ` : 'Director '
                  }ID Country is required`}
                  fieldValue={selectedCountry?.countryCode}
                />
              ) : null}
            </DropdownContainer>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="upload-section center-input mt-7">
              <FileUpload
                titleStart={
                  isChamaAccount ? 'Upload Treasurer’s ' : 'Upload Director’s '
                }
                title={identificationType}
                name="businessChamaDirectorIdentity"
                handleChange={handleCardUpload}
                displayText={
                  selectedIdentificationID ||
                  investor?.businessChamaDirectorIdentity?.passportOrNationalId
                    ? 'Change File'
                    : 'Upload File'
                }
                required={true}
              />

              <UploadedFile
                imageUrl={
                  investor?.businessChamaDirectorIdentity?.passportOrNationalId
                }
                kycSubmittedAt={investor?.kycSubmittedAt}
                loading={uploading.identificationId}
                fileLabel={identificationType}
                handleOpeningFile={handleOpeningFile}
                name={'businessChamaDirectorIdentity'}
                uploadedFile={identificationIdDataUrl}
              />
            </div>
          </div>
          <div className="blue-line mt-10"></div>
          <div className="d-flex justify-content-between flex-wrap">
            {businessAccountIsKenya ? (
              isBusinessAccount ? (
                <DropdownContainer>
                  <DropdownTitle title={'Type of Business'} />

                  <ReusableDropdown
                    handleOpenSelector={() =>
                      setOpenBusinessTypeDropdown(!openBusinessTypeDropdown)
                    }
                    dropdownPlaceholder={'Select Type of Business'}
                    selectedOption={selectedBusinessType?.label}
                    classes={'pr-5 pl-2'}
                    name={'selectedBusinessType'}
                  />

                  {errorExist ? (
                    <FieldErrorMessage
                      errorExist={errorExist}
                      errorMessage={`Type of Business is required`}
                      fieldValue={selectedBusinessType?.value}
                    />
                  ) : null}
                </DropdownContainer>
              ) : (
                <CustomInput
                  type="text"
                  title={
                    businessAccountIsKenya
                      ? 'KRA PIN Number'
                      : 'Business Tax Number'
                  }
                  name="taxPayerNumber"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              )
            ) : (
              <CustomInput
                type="text"
                title={
                  businessAccountIsKenya
                    ? 'KRA PIN Number'
                    : 'Business Tax Number'
                }
                name="taxPayerNumber"
                formik={formik}
                getInputClasses={getInputClasses}
                required={true}
              />
            )}

            <CustomInput
              type="text"
              title={`${capitalizeTheFirstLetterOfEachWord(
                accountType,
              )} Registration Number`}
              name="businessChamaRegistrationNumber"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />
          </div>
          {businessAccountIsKenya && isBusinessAccount ? (
            <div className="d-flex justify-content-between flex-wrap">
              <CustomInput
                type="text"
                title={
                  businessAccountIsKenya
                    ? 'KRA PIN Number'
                    : 'Business Tax Number'
                }
                name="taxPayerNumber"
                formik={formik}
                getInputClasses={getInputClasses}
                required={true}
              />
            </div>
          ) : null}
          <div className="d-flex justify-content-between flex-wrap">
            <div className="upload-section center-input mt-7">
              <FileUpload
                titleStart={
                  isChamaAccount
                    ? 'Upload Chama '
                    : !businessAccountIsKenya
                    ? 'Upload '
                    : 'Upload Business '
                }
                title={
                  !businessAccountIsKenya
                    ? 'Tax Certificate or Equivalent'
                    : 'Tax Pin Certificate'
                }
                name="taxPinCertificate"
                handleChange={handleCardUpload}
                displayText={
                  selectedTaxPinCertificate || investor?.taxPinCertificate
                    ? 'Change File'
                    : 'Upload File'
                }
                required={true}
              />

              <UploadedFile
                imageUrl={investor?.taxPinCertificate}
                kycSubmittedAt={investor?.kycSubmittedAt}
                loading={uploading.taxPinCertificate}
                fileLabel={'Tax Pin Certificate'}
                handleOpeningFile={handleOpeningFile}
                name={'taxPinCertificate'}
                uploadedFile={taxPinCertificateImageDataUrl}
              />
            </div>

            <div className="upload-section center-input mt-7">
              <FileUpload
                titleStart={
                  isChamaAccount ? 'Upload Chama ' : 'Upload Business '
                }
                title="Certificate of Registration"
                name="certificateOfRegistration"
                handleChange={handleCardUpload}
                displayText={
                  selectedCertificateRegistration ||
                  investor?.certificateOfRegistration
                    ? 'Change File'
                    : 'Upload File'
                }
                required={true}
                inputContainerWidth={{ width: '312px' }}
              />

              <UploadedFile
                imageUrl={investor?.certificateOfRegistration}
                kycSubmittedAt={investor?.kycSubmittedAt}
                loading={uploading?.certificateOfRegistration}
                fileLabel={'Certificate of Registration'}
                handleOpeningFile={handleOpeningFile}
                name={'certificateOfRegistration'}
                uploadedFile={certificateRegistrationDataUrl}
              />
            </div>
          </div>
          {isChamaAccount ? (
            <div className="d-flex justify-content-between flex-wrap">
              <div className="upload-section center-input mt-7">
                <FileUpload
                  titleStart="Upload Chama "
                  title="Authorisation Letter"
                  name="authorisationLetter"
                  handleChange={handleCardUpload}
                  displayText={
                    selectedAuthorizationLetter ||
                    investor?.letterOfAuthorization
                      ? 'Change File'
                      : 'Upload File'
                  }
                />
                <UploadedFile
                  imageUrl={investor?.letterOfAuthorization}
                  kycSubmittedAt={investor?.kycSubmittedAt}
                  loading={uploading.authorisationLetter}
                  fileLabel={'Authorisation Letter'}
                  handleOpeningFile={handleOpeningFile}
                  name={'authorisationLetter'}
                  uploadedFile={authorizationLetterImageDataUrl}
                />
              </div>
            </div>
          ) : null}
          {selectedBusinessType?.value ===
          businessType.INCORPORATED_BUSINESS ? (
            <div className="d-flex justify-content-between flex-wrap">
              <div className="upload-section center-input mt-7">
                <FileUpload
                  titleStart="Upload Business "
                  title="Board Resolution Letter"
                  name="boardResolutionLetter"
                  handleChange={handleCardUpload}
                  displayText={
                    selectedBoardResolutionLetter ||
                    investor?.boardResolutionLetter
                      ? 'Change File'
                      : 'Upload File'
                  }
                  required={true}
                />
                <UploadedFile
                  imageUrl={investor?.boardResolutionLetter}
                  kycSubmittedAt={investor?.kycSubmittedAt}
                  loading={uploading?.boardResolutionLetter}
                  fileLabel={'Board Resolution Letter'}
                  handleOpeningFile={handleOpeningFile}
                  name={'boardResolutionLetter'}
                  uploadedFile={boardResolutionDataImageUrl}
                />
              </div>

              <div className="upload-section width-303 center-input mt-7">
                <FileUpload
                  titleStart="Upload "
                  title="CR 12 Certificate"
                  name="CR12Certificate"
                  handleChange={handleCardUpload}
                  displayText={
                    selectedCR12Certificate || investor?.CR12Certificate
                      ? 'Change File'
                      : 'Upload File'
                  }
                  required={true}
                />

                <UploadedFile
                  imageUrl={investor?.CR12Certificate}
                  kycSubmittedAt={investor?.kycSubmittedAt}
                  loading={uploading?.CR12Certificate}
                  fileLabel={'CR 12 Certificate'}
                  handleOpeningFile={handleOpeningFile}
                  name={'CR12Certificate'}
                  uploadedFile={CR12CertificateImageDataUrl}
                />
              </div>
            </div>
          ) : null}
          <div className="text-center mt-5">
            <ValidationErrors
              errorMessage={
                identificationIdError ||
                taxPinCertificateError ||
                certificateRegistrationError ||
                authorizationLetterError ||
                boardResolutionLetterError ||
                CR12CertificateError ||
                error
              }
              alignCenter={'text-center'}
            />{' '}
          </div>
          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Previous"
              handleOnClick={() =>
                history.push('/investor-information/create-account')
              }
              twinButton
            />

            <ConfirmButton
              buttonType={'submit'}
              buttonText="Next"
              loading={loading}
              handleOnClick={handleSubmit}
              twinButton
            />
          </ButtonsContainer>{' '}
        </KycPageContentWrapper>
      </form>

      <OptionsModal
        open={openDropdown}
        handleClose={() => closeDropdown()}
        items={idTypes}
        handleSelect={handleSelectIdType}
        selectedItem={selectedIdType}
        placeholder={'Type of ID'}
        name={'selectedIdType'}
      />

      <OptionsModal
        open={openBusinessTypeDropdown}
        handleClose={() => setOpenBusinessTypeDropdown(false)}
        items={businessTypes}
        handleSelect={handleBusinessTypeChange}
        selectedItem={selectedBusinessType}
        placeholder={'Type of Business'}
        name={'selectedBusinessType'}
      />

      <SelectOptionsModal
        open={openCountryDropdown}
        handleClose={() => setOpenCountryDropdown(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        placeholder={selectCountryPlaceholder}
        selectedCountry={selectedCountry}
      />
    </>
  );
};

export default VerifyBusinessDocuments;
