// AccountCards.jsx
import React from 'react';
import radioBtnSelected from '../../../images/radioBtnSelected.svg';
import disabledRadioButton from '../../../images/disabledRadioButton.svg';
import radioBtn from '../../../images/radioBtn.svg';
import './styles.scss';

import { settlementDetailsType } from '../../../helpers/constants';

const AccountCards = ({
  accountTitle,
  accountNumber,
  currency,
  type,
  onPress,
  selected,
  uniqueId,
  disable,
  errorMessage,
}) => {
  return (
    <div className={disable ? 'disabled-account-cards' : 'account-cards'}>
      <div className="card-left">
        <div
          className={`${!disable ? 'cursor-pointer' : ''} icon`}
          onClick={() => !disable && onPress(accountNumber)}
        >
          {selected === uniqueId ? (
            <img src={radioBtnSelected} alt="Radio button" />
          ) : disable ? (
            <img src={disabledRadioButton} alt="Disabled radio button" />
          ) : (
            <img src={radioBtn} alt="Selected radio button" />
          )}
        </div>
      </div>
      {disable ? (
        <div className="card-right">
          <span className="account-title">{accountTitle}</span>
          <div className="account-details">
            <div>
              {' '}
              {type === settlementDetailsType.MOBILE_MONEY
                ? 'Mobile'
                : 'Account'}
              : {accountNumber}
            </div>
            {errorMessage ? (
              <div className="text-second-orange">{errorMessage}</div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="card-right">
          <span className="account-title">{accountTitle}</span>
          <div className="account-details">
            <div>
              {' '}
              {type === settlementDetailsType.MOBILE_MONEY
                ? 'Mobile'
                : 'Account'}
              : {accountNumber}
            </div>
            <div>Currency: {currency}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountCards;
