import React from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import backArrow from '../../../icons/backArrow.svg';
import './index.scss';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';

const Back = ({
  onClick,
  text,
  title,
  previousPageLink,
  capitalizeEveryFirstLetterOfTheWord = true,
}) => {
  const history = useHistory();

  const smallDevice = useMediaQuery('(max-width : 991px)');

  const handleBackButton = () => {
    localStorage.removeItem('previousPage');
    history.push(
      previousPageLink && previousPageLink !== history.location.pathname
        ? previousPageLink
        : '/home',
    );
  };

  return (
    <div
      style={{
        width: '100%',
        position: 'sticky',
        top: smallDevice ? '60px' : '52px',
        zIndex: '100',
        background: '#ffffff',
      }}
      className="d-flex justify-content-between align-items-center pb-5"
    >
      <div
        onClick={
          previousPageLink
            ? handleBackButton
            : onClick
            ? onClick
            : () => history.goBack()
        }
        className="d-flex align-items-center cursor-pointer"
      >
        <img src={backArrow} alt="Back arrow" />
        <span className="ml-1 text-14">{text ? text : 'Back'}</span>
      </div>

      {title ? (
        <>
          <div className="text-center font-weight-600 page-title">
            {capitalizeEveryFirstLetterOfTheWord
              ? capitalizeTheFirstLetterOfEachWord(title)
              : title}
          </div>
          <div></div>
        </>
      ) : null}
    </div>
  );
};

export default Back;
