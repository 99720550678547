import React from 'react';
import { PlanTitle } from '../../../Common/PlanTitle';
import { RiskLevel } from '../RiskLevel';

const ProfileRisk = ({ riskProfile, bold, goalName, data, squareSize }) => {
  return (
    <div
      className={
        goalName
          ? 'mt-2'
          : 'd-flex justify-content-between align-items-center px-2'
      }
    >
      {goalName ? (
        data ? (
          <PlanTitle data={data} />
        ) : (
          goalName
        )
      ) : (
        <div
          style={{
            color: 'rgb(63, 66, 84)',
            fontSize: '14px',
          }}
          className={`mt-2 ${bold}`}
        >
          <div className="d-flex flex-column p-1 pl-md-2">
            <div className="d-flex align-items-center">
              <img
                className="currency-flag"
                src={data.fundCurrency?.currencyFlag}
                alt="country flag"
              />
              <div className="pl-2 font-weight-500 text-12">
                {data?.fundCurrency?.currency}
              </div>
            </div>
          </div>
        </div>
      )}

      {!goalName && (
        <RiskLevel
          planRiskLevel={riskProfile}
          squareSize={'12px'}
          riskLevelFontSize="text-13"
          extremelyLowSquareWidth={'6px'}
        />
      )}
    </div>
  );
};

export default ProfileRisk;
