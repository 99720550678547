import React from 'react';
import { SmallSquare } from './SmallSquare';

const HighRisk = ({ squareSize }) => {
  const squares = [0, 1, 2, 3];
  return (
    <div className="d-flex justify-content-end">
      <SmallSquare squareSize={squareSize} color={'blue'} />
      <SmallSquare squareSize={squareSize} color={'blue'} />
      <SmallSquare squareSize={squareSize} color={'blue'} />

      {squares.map(item => (
        <SmallSquare key={item} squareSize={squareSize} color={'gray'} />
      ))}
    </div>
  );
};

export default HighRisk;
