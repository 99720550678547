import React from 'react';

export const HowItWorksModalContent = () => {
  return (
    <div>
      <div className="text-18 font-weight-600 text-dark-blue-800 mb-7">
        Why Choose Ndovu?
      </div>
      <di className="text-16">
        We are regulated by CMA Kenya. Your money is secure. All funds and
        investments are held in a Custodian Bank, ensuring your assets stay safe
        and accessible, no matter what happens to Ndovu.
      </di>
    </div>
  );
};
