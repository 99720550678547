import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { kycStatuses } from '../../../helpers/constants';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import './KycReminder.scss';
import {
  PendingAndInEditKycBanner,
  RejectedKycBanner,
  SubmittedKycBanner,
} from './Banners';

const FixedKycReminder = ({ handleOnclick }) => {
  const { investor } = useSelector(state => state.profile);
  const { kycLevel, kycStatus, pendingKyc, investorType } = investor;
  const [isHovered, setIsHovered] = useState(false);

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const history = useHistory();

  const handleClick = () => {
    if (handleOnclick && kycStatus === kycStatuses.REJECTED) {
      handleOnclick();
      return;
    }

    localStorage.setItem('previousPage', history.location.pathname);
    const redirectLink = handleKycRouting(
      kycChecks,
      '/manage-account',
      investorType,
    );

    const updating = kycStatus === kycStatuses.IN_EDIT;

    history.push(
      updating ? '/manage-account/investor-information/details' : redirectLink,
    );
  };

  const kycBanner = {
    [kycStatuses.PENDING]: (
      <PendingAndInEditKycBanner
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        handleClick={handleClick}
        kycStatus={kycStatus}
      />
    ),
    [kycStatuses.IN_EDIT]: (
      <PendingAndInEditKycBanner
        handleClick={handleClick}
        kycStatus={kycStatus}
      />
    ),
    [kycStatuses.SUBMITTED]: (
      <SubmittedKycBanner handleClick={handleClick} kycStatus={kycStatus} />
    ),
    [kycStatuses.REJECTED]: (
      <RejectedKycBanner handleClick={handleClick} kycStatus={kycStatus} />
    ),
  };

  return kycStatus !== kycStatuses.COMPLETED ? kycBanner[kycStatus] : <></>;
};

export default FixedKycReminder;
