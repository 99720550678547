export const routes = {
  // :TODO: REFACTOR THIS OBJECT (all this routes are not part of the wizard)
  wizard: {
    home: '/wizard',
    age: '/wizard/age',
    purpose: '/wizard/purpose',
    monthlyIncome: '/wizard/monthlyIncome',
    monthlySavings: '/wizard/monthlySavings',
    cashSavings: '/wizard/cashSavings',
    timeFrame: '/wizard/timeFrame',
    investmentExperience: '/wizard/investmentExperience',
    familiarInvestments: '/wizard/familiarInvestments',
    investmentAttitude: '/wizard/investmentAttitude',
    tenPercentInvestmentLoss: '/wizard/tenPercentInvestmentLoss',
    personalDetails: '/wizard/personalDetails',
    register: '/auth/registration',
    login: '/auth/login',
    googlecallback: '/auth/google-callback',
    resetPassword: '/auth/resetpassword',
    resendEmailVerification: '/auth/resendVerification',
    forgotPassword: '/auth/resetpasswordconfirmation',
    selectAccountType: '/auth/selectAccountType',
    selectedAccountType: '/auth/accountDetails',
    selectSignUpOption: '/auth/select-sign-up-option',
  },
};
