import React from 'react';
import { useHistory } from 'react-router-dom';
import { FundType } from '../../helpers/constants';
import { messages } from '../../utils/messages';
import PopoverComponent from './PopoverComponent';
import PlanTypeComponent from '../Plan/components/PlanTypeComponent';
import { popoverMessage } from '../../helpers/investmentHelpers';

export const PlanTitle = ({ data, className, planTypeName }) => {
  const history = useHistory();
  const content = <div>{messages.annualPerformance}</div>;
  const savingFundContent = (
    <div>
      <span>This is the fixed return earned per year.</span>
      <span className="font-weight-bold"> TAX FREE</span>
    </div>
  );
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${className}`}
    >
      <div onClick={() => history.push(`/plans/${data?.id}`)}>
        <span className="text-14 font-weight-600 blue-color-text">
          {data?.coolName || data?.name}
        </span>
      </div>

      {planTypeName ? (
        <PlanTypeComponent
          planTypeName={planTypeName}
          popoverContent={popoverMessage(planTypeName?.toLowerCase())}
        />
      ) : (
        <div>
          <span className="p-1 text-dark pl-md-5 td-font-size-14 text-capitalize">
            {data?.planType?.name}
          </span>
          <PopoverComponent
            popoverContent={
              data.fundType === FundType.SAVE ? savingFundContent : content
            }
          >
            <span>
              <i
                style={{
                  fontSize: '20px',
                  color: '#0071ce',
                  marginLeft: '5px',
                }}
                className="fa fa-question-circle"
                aria-hidden="true"
              ></i>
            </span>
          </PopoverComponent>
        </div>
      )}
    </div>
  );
};
