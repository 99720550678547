import React from 'react';

const Card = ({ children, onClick, width, classes }) => {
  return (
    <div
      className={`card gutter-b cursor-pointer plans-card border-radius-8 ${width} ${classes}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
