import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import * as profile from '../../../Profile/_redux/profileRedux';
import Button from '../../../Common/Button';

import RiskProfileImage from './RiskProfileImage';
import DisplayImage from './DisplayImage';

function RiskProfile({ onInvestorType, toWizardBtn }) {
  const { investor, user } = useSelector(state => state.profile);

  const generateRiskProfileDescription = riskProfileAlias => {
    if (riskProfileAlias?.id === 1) {
      return `A ${riskProfileAlias?.alias.toLowerCase()} investor is someone who wants to grow their money safely.  You prefer stability in your portfolio.  You should expect your money to grow steadily over time.`;
    }

    if (riskProfileAlias?.id === 2) {
      return `A ${riskProfileAlias?.alias.toLowerCase()} investor is someone who wants to grow their money whilst also protecting their investment.  You are willing to accept moderate market ups and downs in exchange for the possibility of receiving higher returns over time.`;
    }

    if (riskProfileAlias?.id === 3) {
      return `An ${riskProfileAlias?.alias.toLowerCase()} investor is someone whose key focus is to grow their money.  You are willing to accept extreme market ups and downs in exchange for the possibility of receiving significantly higher returns over time.`;
    }
  };

  const { elephant, image, name } = DisplayImage(investor?.riskProfile?.id);

  return (
    <>
      {user.firstName !== null && (
        <div className="w-100">
          <div className="risk-profile-info-container p-5">
            <RiskProfileImage image={elephant} />
            <div className="text-center">
              <p className="risk-profile-alias">
                {investor?.riskProfile?.alias} Investor
              </p>
            </div>
            <div className="text-center font-size-16 mx-10 mt-5">
              {generateRiskProfileDescription(investor?.riskProfile)}
            </div>

            <div className="d-flex justify-content-center mt-20">
              <div className="symbol symbol-50 symbol-lg-60">
                <img alt="Investment model" src={image} />
              </div>
              <div
                style={{ marginTop: '-8px' }}
                className="ml-5 d-flex flex-column"
              >
                <span className="model-name">{name}</span>
                <small
                  style={{ marginTop: '-5px' }}
                  className="font-weight-bold"
                >
                  Your investing style
                </small>
              </div>
            </div>

            <div className="break-line mt-5"></div>
            {onInvestorType ? (
              toWizardBtn
            ) : (
              <div className="text-center mt-20 mb-10">
                <Link to="/referral-promo">
                  <Button
                    btnText="Next"
                    className="common-btn pl-13 pr-15 py-3"
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { ...profile.actions })(RiskProfile);
