import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';
import Alert from '../../Common/Alert';
import {
  countryCode,
  idTypeOptions,
  idTypes,
  kycStatuses,
} from '../../../helpers/constants';
import CustomInput from '../../Common/CustomInput';
import FileUpload from '../../Business/helpers/FileUpload';
import { downloadUsersFile } from '../../Profile/_redux/profileCrud';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  compressImage,
  generateImageDataUrl,
  isFileNameValid,
  theFileIsLessThanOrEqualToMaximumSize,
  validateFile,
} from '../../../helpers/validateFiles';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import { UploadedFile } from '../../Business/helpers/UploadedFile';
import Back from '../../Common/BackArrow';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import { KycCompletedModalContent } from '../../Common/KycCompletedModalContent';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import OptionsModal from '../../Common/OptionsModal';
import ReusableDropdown from '../../Common/ReusableDropdown';
import { sendFormDataToBackend } from '../../Individual/_redux/individualKYCAction';
import * as kyc from '../../KYC/_redux/kycRedux';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import Dropdown from '../../Common/Dropdown';
import { Country } from 'country-state-city';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import getCountries from '../../../helpers/countries';

const Documents = props => {
  let history = useHistory();
  const { hideButtons, disableInput, handleStepChange, id: stepperId } = props;
  const [loading, setLoading] = useState(false);
  const profile = useSelector(state => state.profile);
  const { investor, user } = profile;

  // DOCUMENT UPLOAD
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState({
    identificationFrontSide: false,
    taxPinCertificate: false,
    facePhoto: false,
    proofOfAddres: false,
  });
  const [allCountries, setAllCountries] = useState();
  const [frontFileNameError, setFrontFileNameError] = useState(null);
  const [taxPinCertificateError, setTaxPinCertificateError] = useState(null);
  const [facePhotoError, setFacePhotoError] = useState(null);
  const [proofOfAddresError, setProofOfAddressError] = useState(null);

  const [frontIsValid, setFrontIsValid] = useState(true);
  const [facePhotoIsValid, setFacePhotoIsValid] = useState(true);
  const [proofOfAddresIsValid, setProofOfAddressIsValid] = useState(true);
  const [openCountryDropdown, setOpenCountryDropdown] = useState(false);
  const [taxPinCertificateIsValid, setTaxPinCertificateIsValid] = useState(
    true,
  );
  const [error, setError] = useState();

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [selectedFront, setSelectedFront] = useState();
  const [selectedFrontDataUrl, setSelectedFrontDataUrl] = useState();

  const [selectedfacePhoto, setSelectedfacePhoto] = useState();
  const [facePhotoDataUrl, setFacePhotoDataUrl] = useState();

  const [selectedTaxPinCertificate, setSelectedTaxPinCertificate] = useState();
  const [
    taxPinCertificateImageData,
    setTaxPinCertificateImageData,
  ] = useState();

  const [selectedProofOfAddress, setSelectedProofOfAddress] = useState();
  const [proofOfAddressImageData, setProofOfAddressImageData] = useState();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    investor?.identificationType
      ? {
          label: idTypeOptions[investor?.identificationType?.toUpperCase()],
          value: investor?.identificationType?.toUpperCase(),
        }
      : {
          label: 'National ID',
          value: 'NATIONAL_ID',
        },
  );

  const [selectedCountry, setSelectedCountry] = useState(
    investor?.identificationCountry
      ? {
          countryFlag: `flag-icon-${investor?.identificationCountry?.toLowerCase()}`,
          currency: Country.getCountryByCode(investor?.identificationCountry)
            ?.name,
          countryCode: investor?.identificationCountry,
        }
      : {
          countryFlag: null,
          currency: null,
          countryCode: null,
        },
  );

  const [fieldsError, setFieldsError] = useState();
  const [updatedProfile, setUpdatedProfile] = useState();
  const [show, setShow] = useState(false);
  const kycInformation = useSelector(state => state.kyc);
  const dispatch = useDispatch();

  const FILE_SIZE = 5 * 1024 * 1024;

  useEffect(() => {
    const countries = getCountries();
    handleStepChange({ next: stepperId });
    setAllCountries(countries);
  }, []);

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleOpeningFile = async link => {
    downloadUsersFile(link)
      .then(response => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      })
      .catch(() => {
        setAlert('Failed to download this document');
      });
  };

  const handleCardUpload = async (e, name) => {
    const file = e.target.files[0];
    setFrontFileNameError(null);
    setTaxPinCertificateError(null);
    setFacePhotoError(null);
    setProofOfAddressError(null);

    if (!file) {
      return;
    }

    if (name === 'identificationFrontSide') {
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setFrontFileNameError(result?.error);
        return;
      }

      setUploading({ identificationFrontSide: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setFrontFileNameError(validationError?.error);
        setUploading({ identificationFrontSide: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setFrontFileNameError('ID should be Max 5MB');
          setUploading({ identificationFrontSide: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setFrontFileNameError('ID should be Max 5MB');
        setUploading({ identificationFrontSide: false });
        return;
      }

      setSelectedFront(newFile);
      setFacePhotoIsValid(true);
      setFrontFileNameError(null);
      frontIsValid && setError(null);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setSelectedFrontDataUrl(fileDataUrl);
          setUploading({ identificationFrontSide: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ identificationFrontSide: false });
        });
    }

    if (name === 'facePhoto') {
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setFacePhotoError(result?.error);
        return;
      }

      setUploading({ facePhoto: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setFacePhotoError(validationError?.error);
        setUploading({ facePhoto: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setFacePhotoError('Face Photo should be Max 5MB');
          setUploading({ facePhoto: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setFacePhotoError('Face Photo should be Max 5MB');
        setUploading({ facePhoto: false });
        return;
      }

      setSelectedfacePhoto(newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setFacePhotoDataUrl(fileDataUrl);
          setUploading({ facePhoto: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ facePhoto: false });
        });
      setFrontIsValid(true);
      setFacePhotoError(null);
      facePhotoIsValid && setError(null);
    }

    if (name === 'proofOfAddress') {
      // Validate File name
      setProofOfAddressError(null);
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setProofOfAddressError(result?.error);
        return;
      }

      setUploading({ proofOfAddress: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setProofOfAddressError(validationError?.error);
        setUploading({ proofOfAddress: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setProofOfAddressError('Proof of Address should be Max 5MB');
          setUploading({ proofOfAddress: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setProofOfAddressError('Proof of Address should be Max 5MB');
        setUploading({ proofOfAddress: false });
        return;
      }

      setSelectedProofOfAddress(newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setProofOfAddressImageData(fileDataUrl);
          setUploading({ proofOfAddress: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ proofOfAddress: false });
        });

      setProofOfAddressIsValid(true);
      setProofOfAddressError(null);
      proofOfAddresIsValid && setError(null);
    }

    if (name === 'taxPinCertificate') {
      setUploading({ taxPinCertificate: true });
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setTaxPinCertificateError(result?.error);
        setUploading({ taxPinCertificate: false });
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setTaxPinCertificateError(validationError?.error);
        setUploading({ taxPinCertificate: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setTaxPinCertificateError('Certificate should be Max 5MB');
          setUploading({ taxPinCertificate: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setTaxPinCertificateError('Certificate should be Max 5MB');
        setUploading({ taxPinCertificate: false });
        return;
      }

      setSelectedTaxPinCertificate(newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setTaxPinCertificateImageData(fileDataUrl);
          setUploading({ taxPinCertificate: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ taxPinCertificate: false });
        });

      setTaxPinCertificateIsValid(true);
      setTaxPinCertificateError(null);
      taxPinCertificateIsValid && setError(null);
    }
  };

  const initialValues = {
    identificationType: investor?.identificationType || '',
    identificationNumber: investor?.identificationNumber || '',
    taxPayerNumber: investor?.taxPayerNumber || '',
  };

  const DetailsSchema = Yup.object().shape({
    identificationType: Yup.string().required('ID Type is required'),
    identificationNumber: Yup.string().required(
      'Identification Number is required',
    ),
    taxPayerNumber: Yup.string().nullable(), // Allow null values
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: DetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (values !== '') {
        setFieldsError(null);
        let errors = {};

        if (!selectedCountry?.countryCode) {
          errors.identificationCountry = `${
            idTypeOptions[selectedOption?.value]
          } Country`;
        }

        if (!selectedCountry?.countryCode || !selectedOption?.value) {
          setFieldsError(errors);
          return;
        }

        setLoading(true);

        dispatch(
          kyc?.actions?.updateKycInfo({
            identificationNumber: values.identificationNumber,
            taxPayerNumber: values.taxPayerNumber,
            identificationType: values.identificationType,
          }),
        );
        setSubmitting(true);
      }

      const formData = new FormData();

      const thisPageFields = [
        'identificationType',
        'identificationNumber',
        'taxPayerNumber',
      ];

      for (const key in kycInformation) {
        if (
          kycInformation[key] &&
          kycInformation[key]?.length &&
          !thisPageFields?.includes(key)
        ) {
          formData.append(key, kycInformation[key]);
        }
      }

      formData.append('identificationType', selectedOption?.value);
      formData.append('identificationNumber', values.identificationNumber);

      investor?.kycLevel?.level >= 2 &&
        formData.append('taxPayerNumber', values.taxPayerNumber);

      // Append selected files
      if (selectedFront) {
        formData.append('identificationFrontSide', selectedFront);
      }
      if (selectedfacePhoto) {
        formData.append('facePhoto', selectedfacePhoto);
      }
      if (selectedTaxPinCertificate) {
        formData.append('taxPinCertificate', selectedTaxPinCertificate);
      }
      if (selectedProofOfAddress) {
        formData.append('proofOfAddress', selectedProofOfAddress);
      }

      try {
        const {
          data: { data: userInformation },
        } = await sendFormDataToBackend(formData);

        const investorInformation = userInformation?.investors[0];
        delete userInformation?.investors;

        setUpdatedProfile({
          ...profile,
          investor: investorInformation,
          user: userInformation,
          loading: false,
        });

        dispatch(kyc?.actions?.cleanKycState());
        setLoading(false);
        setShow(true);
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
        setLoading(false);
      }
    },
  });

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleSelectOption = item => {
    setSelectedOption(item);
    setOpenDropdown(false);
  };

  const handleHide = () => {
    setShow(false);
    dispatch(investorProfileActions.profileAction(updatedProfile));
    history.push('/plans');
  };

  const closeCountryDropdown = () => {
    setOpenCountryDropdown(false);
  };

  const handleCountryDropdown = () => {
    setOpenCountryDropdown(!openCountryDropdown);
  };

  const handleSelect = country => {
    setFieldsError({ ...fieldsError, identificationCountry: null });
    setSelectedCountry({
      countryFlag: country?.flag,
      currency: country?.name,
      countryCode: country?.countryCode,
    });
    closeCountryDropdown();
  };

  const generateDocumentLabel = () => {
    if (
      (investor?.identificationType === 'NATIONAL_ID' ||
        selectedOption?.value === 'NATIONAL_ID') &&
      selectedOption?.value !== 'PASSPORT'
    ) {
      return 'National ID';
    } else {
      return 'Passport';
    }
  };

  const disableButton =
    taxPinCertificateError ||
    frontFileNameError ||
    proofOfAddresError ||
    facePhotoError;

  return (
    <>
      {hideButtons ? (
        <Back text={'Back'} onClick={() => history.goBack()} />
      ) : (
        <></>
      )}
      <form className="mt-5" id="kt_form" onSubmit={formik?.handleSubmit}>
        <KycPageContentWrapper>
          {alertOn && <Alert alert={alert} />}
          <small className="font-weight-bold text-danger justify-content-center d-flex">
            {error ? error : ''}
          </small>
          {hideButtons ? null : (
            <div className="px-0 px-md-20 px-lg-20">
              <p className="px-0 font-weight-bold text-center px-md-20 px-lg-20">
                Documents
              </p>
            </div>
          )}

          <div className="d-flex justify-content-between flex-wrap">
            <DropdownContainer>
              <DropdownTitle title={'ID Type'} />

              <ReusableDropdown
                handleOpenSelector={handleDropdown}
                dropdownPlaceholder={'Select Document Type'}
                selectedOption={selectedOption?.label}
                classes={'pr-2'}
                name={'identificationType'}
              />
            </DropdownContainer>

            <DropdownContainer>
              <DropdownTitle
                title={`${idTypeOptions[selectedOption?.value]} Country`}
              />

              <Dropdown
                handleOpenSelector={handleCountryDropdown}
                dropdownPlaceholder={'Select Country'}
                selectedOption={selectedCountry}
                classes={'py-3 pr-5 pl-2'}
                name={'identificationCountry'}
              />

              {fieldsError?.identificationCountry ? (
                <ValidationErrors
                  errorMessage={fieldsError?.identificationCountry}
                />
              ) : null}
            </DropdownContainer>
          </div>

          <CustomInput
            type="text"
            title="Identification Number"
            name="identificationNumber"
            formik={formik}
            getInputClasses={getInputClasses}
            required={true}
          />

          <div className="d-flex justify-content-between flex-wrap">
            <div className="upload-section center-input mt-7">
              <FileUpload
                titleStart="Upload "
                title={generateDocumentLabel()}
                name="identificationFrontSide"
                handleChange={handleCardUpload}
                displayText={
                  selectedFront || investor?.identificationFrontSide
                    ? 'Change File'
                    : 'Upload File'
                }
                required={true}
                disabled={disableInput}
              />
              <UploadedFile
                imageUrl={investor?.identificationFrontSide}
                kycSubmittedAt={investor?.kycSubmittedAt}
                loading={uploading.identificationFrontSide}
                fileLabel={
                  idTypeOptions[
                    selectedOption?.value || investor?.identificationType
                  ]
                }
                files={files}
                handleOpeningFile={handleOpeningFile}
                name={'identificationFrontSide'}
                uploadedFile={selectedFrontDataUrl}
              />
            </div>

            <div className="upload-section center-input mt-7">
              <FileUpload
                titleStart="Upload "
                title="Passport Size Photo"
                name="facePhoto"
                handleChange={handleCardUpload}
                displayText={
                  selectedfacePhoto || investor?.facePhoto
                    ? 'Change File'
                    : 'Upload File'
                }
                required={true}
                disabled={disableInput}
              />

              <UploadedFile
                imageUrl={investor?.facePhoto}
                kycSubmittedAt={investor?.kycSubmittedAt}
                loading={uploading.facePhoto}
                fileLabel={'Passport Size Photo'}
                handleOpeningFile={handleOpeningFile}
                name={'facePhoto'}
                uploadedFile={facePhotoDataUrl}
              />
            </div>
          </div>

          {investor?.kycLevel?.level > 1 ? (
            <div className="d-flex justify-content-between flex-wrap">
              <CustomInput
                type="text"
                title={
                  investor?.country?.toUpperCase() === 'KE' ? (
                    <>
                      <span className="font-weight-600">KRA</span>
                      <span> PIN Number</span>
                    </>
                  ) : (
                    <span> Tax Payer Number</span>
                  )
                }
                name="taxPayerNumber"
                formik={formik}
                getInputClasses={getInputClasses}
                required={true}
              />

              {(investor?.country === countryCode.KE &&
                kycInformation?.country === countryCode.KE) ||
              kycInformation?.country === countryCode.KE ? (
                <div className="upload-section center-input mt-7">
                  <FileUpload
                    titleStart="Upload "
                    title="Tax PIN Certificate"
                    name="taxPinCertificate"
                    handleChange={handleCardUpload}
                    displayText={
                      selectedTaxPinCertificate || investor?.taxPinCertificate
                        ? 'Change File'
                        : 'Upload File'
                    }
                    required={true}
                  />

                  <UploadedFile
                    imageUrl={investor?.taxPinCertificate}
                    kycSubmittedAt={investor?.kycSubmittedAt}
                    loading={uploading.taxPinCertificate}
                    fileLabel={'Tax Pin Certificate'}
                    handleOpeningFile={handleOpeningFile}
                    name={'taxPinCertificate'}
                    uploadedFile={taxPinCertificateImageData}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {investor?.kycLevel?.level === 3 ? (
            <div className="upload-section center-input mt-7">
              <FileUpload
                titleStart="Upload "
                title="Self-Declaration Proof of Address"
                name="proofOfAddress"
                handleChange={handleCardUpload}
                displayText={
                  selectedProofOfAddress || investor?.proofOfAddress
                    ? 'Change File'
                    : 'Upload File'
                }
                required={true}
              />

              <UploadedFile
                imageUrl={investor?.proofOfAddress}
                kycSubmittedAt={investor?.kycSubmittedAt}
                loading={uploading.proofOfAddress}
                fileLabel={'Proof of Address'}
                handleOpeningFile={handleOpeningFile}
                name={'proofOfAddress'}
                uploadedFile={proofOfAddressImageData}
              />
            </div>
          ) : null}

          {hideButtons ? (
            <ButtonsContainer justifyContent="justify-content-between">
              <CancelButton
                buttonText="Cancel"
                handleOnClick={() =>
                  history.push('/manage-account/information')
                }
                twinButton
              />

              <ConfirmButton
                buttonType={'submit'}
                buttonText={'Complete'}
                loading={loading}
                disable={disableButton}
                twinButton
              />
            </ButtonsContainer>
          ) : null}

          <div className="text-center mt-5">
            <ValidationErrors
              errorMessage={
                taxPinCertificateError ||
                frontFileNameError ||
                proofOfAddresError ||
                facePhotoError
              }
            />{' '}
          </div>
        </KycPageContentWrapper>
      </form>

      <OptionsModal
        open={openDropdown}
        handleClose={() => setOpenDropdown(false)}
        items={idTypes}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption}
        placeholder={'Select Document Type'}
        name={'identificationType'}
      />

      <SelectOptionsModal
        open={openCountryDropdown}
        handleClose={() => setOpenCountryDropdown(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        placeholder={'Select Country'}
        selectedCountry={selectedCountry}
      />

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={
          <KycCompletedModalContent investor={investor} userIsUpdating={true} />
        }
        handleButtonClick={handleHide}
        moreClasses={'bg-white'}
        btnText="Invest"
      />
    </>
  );
};

const mapStateToProps = ({ kyc, profile }) => ({
  kyc,
  profile,
});

export default injectIntl(connect(mapStateToProps, kyc.actions)(Documents));
