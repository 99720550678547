import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { getConversion } from '../../../helpers/getConversion';
import { addCommas } from '../../../helpers/handleInput';
import Back from '../../Common/BackArrow';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorModal from '../../Common/ErrorModal';
import { Note } from '../../Common/Note';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { withdrawFromPocket } from '../../Pocket/_redux/axiosRequest';
import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import { pocketContents } from '../../Pocket/copies';
import { pocketBalanceIsLessThanMinimumWithdrawableAmount } from '../../Pocket/helpers/amountHelper';
import { ConfirmTransactionDetailsPageWrapper } from '../components/ConfirmTransactionsDetailsPageWrapper';
import { TransactionDetailsItem } from '../components/TransactionDetailsItem';

const Settlement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pocketDetails = useSelector(state => state.pocketDetails);
  const pocketFlow = useSelector(state => state.pocketFlow);
  const { type } = useSelector(state => state.pocketFlow);
  const { conversions } = useSelector(state => state.conversions);
  const { currencies } = useSelector(state => state.currencies);
  const [offeredConversionRate, setOfferedConversionRate] = useState();
  const [currency, setCurrency] = useState();

  const alertInitialState = {
    alertMessage: null,
    alertMessageType: null,
  };
  const [alert, setAlert] = useState(alertInitialState);
  const [popupError, setPopupError] = useState(alertInitialState);
  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(
    false,
  );
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currency: pocketCurrency } = pocketDetails || {};
  const settlementCurrency =
    pocketFlow?.settlementDetails?.settlementAccountCurrency;
  const inputAmount = pocketFlow?.inputOne?.amount || 0;
  const withdrawalFee = currency?.pocketFlatWithdrawalFee || 0;

  useEffect(() => {
    const getOfferedConversionRate = async () => {
      const rate = await getConversionRate();

      setOfferedConversionRate(rate);
    };

    const selectedCurrency = currencies?.find(
      currency => currency?.currency === settlementCurrency,
    );
    setCurrency(selectedCurrency);

    getOfferedConversionRate();
  }, []);

  const clearState = () => {
    dispatch(pocketActions.clearPocketFlow());
    dispatch(pocketActions.updatePocket(null));
  };

  const modalContent = (
    <>
      {
        pocketContents({
          inputOne: {
            amount: inputAmount,
            currency: pocketCurrency,
          },
        })?.[type?.path]?.congratulationsModalContent
      }
    </>
  );

  const ErrorModalContent = <>{popupError?.alertMessage}</>;

  const { settlementId } = useParams();

  const handleErrorMessage = (errorMessage, insideModal) => {
    insideModal
      ? setPopupError({ alertMessage: errorMessage, alertMessageType: 'error' })
      : setAlert({ alertMessage: errorMessage, alertMessageType: 'error' });
    setOpenErrorModal(true);
  };

  const handleSuccessResponseFromModal = () => {
    setOpenCongratulationsModal(true);
  };

  const handleWithdrawal = async () => {
    setLoading(true);
    try {
      const response = await withdrawFromPocket(
        {
          amount: convertAmountToNumber(inputAmount),
          settlementDetailId: convertAmountToNumber(settlementId),
        },
        pocketDetails.id,
      );

      handleSuccessResponseFromModal(response?.data?.success);
      setLoading(false);
    } catch (error) {
      handleErrorMessage(error?.response?.data?.message, true);
      setLoading(false);
    }
  };

  const getConversionRate = async () => {
    const rate = await getConversion(
      pocketCurrency,
      settlementCurrency,
      conversions,
    );

    return await rate.offeredConversionRate;
  };

  const balanceIsLessThanMinimumWithdrawableAmount = pocketBalanceIsLessThanMinimumWithdrawableAmount(
    pocketDetails?.balance,
    currency?.minimumWithdrawableAmount,
    pocketFlow?.type?.path,
  );

  const roundAmountToTwoDecimalNumbers = false;

  const getPayableAmount = () => {
    let baseAmount = convertAmountToNumber(inputAmount);

    // Adjust amount for conversion if currencies differ
    if (pocketCurrency !== settlementCurrency) {
      baseAmount *= offeredConversionRate;
    }

    baseAmount -= withdrawalFee;

    // Do not round if the balance is less than withdrawable amount, the BE is withdrawing the whole amount
    return balanceIsLessThanMinimumWithdrawableAmount
      ? addCommas(baseAmount, roundAmountToTwoDecimalNumbers)
      : addCommas(baseAmount);
  };

  const getTransactionFee = () => {
    let transactionFee;
    if (pocketCurrency !== settlementCurrency) {
      transactionFee = withdrawalFee * offeredConversionRate;
    } else {
      transactionFee = withdrawalFee;
    }

    return addCommas(transactionFee);
  };

  return (
    <>
      <Back title={'Transaction Confirmation'} text={'Back'} />
      <div className="mt-5">
        <ConfirmTransactionDetailsPageWrapper
          title={'Confirm Transaction Details'}
        >
          <TransactionDetailsItem
            label={'Withdrawn Amount'}
            currency={pocketCurrency}
            value={
              balanceIsLessThanMinimumWithdrawableAmount
                ? addCommas(inputAmount, roundAmountToTwoDecimalNumbers)
                : addCommas(inputAmount)
            }
          />

          <TransactionDetailsItem
            label={'Transaction Fee'}
            currency={settlementCurrency}
            value={withdrawalFee}
          />

          {pocketCurrency !== settlementCurrency ? (
            <TransactionDetailsItem
              label={'Forex Conversion Rate'}
              currency={settlementCurrency}
              value={addCommas(
                offeredConversionRate,
                roundAmountToTwoDecimalNumbers,
              )}
            />
          ) : null}

          <TransactionDetailsItem
            label={'Payable Amount'}
            currency={
              pocketCurrency !== settlementCurrency
                ? settlementCurrency
                : pocketCurrency
            }
            value={getPayableAmount()}
          />

          <Note
            title={'Note:'}
            content={
              'The transfer of your money to your account may take up to 2 business days. Standard bank and mobile money charges may apply'
            }
            titleColor="text-primary-orange"
            contentColor="text-primary-orange"
            alignText="text-left"
          />

          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Cancel"
              handleOnClick={() => {
                history.goBack();
              }}
              twinButton
            />

            <ConfirmButton
              buttonText={'Confirm'}
              buttonType={'submit'}
              loading={loading}
              handleOnClick={() => handleWithdrawal()}
              disable={loading}
              twinButton
            />
          </ButtonsContainer>
        </ConfirmTransactionDetailsPageWrapper>
      </div>

      <CommonSuccessModal
        open={openCongratulationsModal}
        setOpen={() => setOpenCongratulationsModal(false)}
        content={modalContent}
        moreClasses={'bg-white'}
        handleButtonClick={() => {
          clearState();
          history.push(`/pocket/${pocketDetails?.id}`);
        }}
        // iconName={pocketContents()?.[type?.path]?.modalIconName}
      />

      <ErrorModal
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        content={ErrorModalContent}
        moreClasses="bg-orange-100"
        btnLink={`/pocket/${pocketDetails?.id}`}
        clearState={() => {
          dispatch(pocketActions.updatePocket(null));
        }}
      />
    </>
  );
};

export default Settlement;
