import React from 'react';

export const PasswordValidationErrorMessage = ({ thereIsAnError }) => {
  return (
    <>
      Password must contain:{' '}
      <span className={thereIsAnError ? 'text-red-500' : 'text-second-green'}>
        At least 8 characters, at most 40 characters, one number, special
        Character ie. @#%, an upper case and lowercase letter
      </span>
    </>
  );
};
