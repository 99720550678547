import React from 'react';

export const SmallSquare = ({ squareSize, color }) => {
  return (
    <span
      className={`risk-profile-little-square ${color} ml-1`}
      style={{ width: squareSize, height: squareSize }}
    ></span>
  );
};
