import React from 'react';

export const AdditionalInformationRequiredContent = () => {
  return (
    <div>
      <div className="text-18 font-weight-600 text-dark-blue-800">
        Additional Information Required
      </div>
      <div className="text-16 mt-7">
        Verify your identity to start earning. Note: Your investment can not be
        reconciled until the required information is provided.
      </div>
    </div>
  );
};
